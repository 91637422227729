package common

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet.Sizes.paddingMd

@Composable
fun Row(
  attrs: AttrBuilderContext<HTMLDivElement>? = null,
  content: ContentBuilder<HTMLDivElement>? = null,
) {
  Div({
    style {
      marginTop(0.px)
      marginBottom(paddingMd)
    }
    attrs?.invoke(this)
  }, content = content)
}

@Composable
fun FlexRow(
  attrs: AttrBuilderContext<HTMLDivElement>? = null,
  content: ContentBuilder<HTMLDivElement>? = null,
) {
  Row({
    style {
      display(DisplayStyle.Flex)
      flexDirection(FlexDirection.Row)
      alignItems(AlignItems.Center)
      gap(paddingMd)
    }
    attrs?.invoke(this)
  }, content = content)
}

@Composable
fun FlexColumn(
  attrs: AttrBuilderContext<HTMLDivElement>? = null,
  alignItems: AlignItems = AlignItems.Stretch,
  content: ContentBuilder<HTMLDivElement>? = null,
) {
  Div({
    style {
      display(DisplayStyle.Flex)
      flexDirection(FlexDirection.Column)
      flexGrow(1)
      alignItems(alignItems)
    }
    attrs?.invoke(this)
  }, content = content)
}
