package forms

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import builders.ListingPriceAndClosingBuilder
import com.diyoffer.negotiation.common.formatCurrency
import com.diyoffer.negotiation.common.formatDate
import com.diyoffer.negotiation.common.formatDateTime
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.services.DateService
import common.DatePicker
import common.FlatList
import common.FlexColumn
import common.FlexRow
import common.HelpChip
import common.MoneyField
import common.RadioButton
import common.Row
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.rememberInstance
import style.DiyStyleSheet.Sizes.paddingMd
import style.DiyStyleSheet.Sizes.paddingSm
import style.DiyStyleSheet.darkGreyText

@Suppress("LongMethod")
@Composable
fun ListingDetailsEdit(
  builder: ListingPriceAndClosingBuilder,
  setBuilder: (ListingPriceAndClosingBuilder) -> Unit,
) {
  val dateService by rememberInstance<DateService>()

  // This is a workaround to use a suspend service to validate the builder.
  LaunchedEffect(builder.closingDate) {
    builder.closingDate?.let {
      setBuilder(
        builder.copy(
          errorMessage = if (!dateService.isBusinessDay(it)) {
            "Closing Date must be on a week day that is not a statutory holiday."
          } else {
            null
          }
        )
      )
    }
  }
  FlexRow({
    style {
      marginTop(paddingMd)
      marginBottom(paddingSm)
    }
  }) {
    MoneyField(builder.currency, {
      label = "Listing Price (${builder.currency.name})"
      value = builder.price
      onModified = { setBuilder(builder.copy(price = it)) }
    })
    HelpChip("listing-listing-price", 22.px)
  }
  FlexRow({ style { marginBottom(paddingSm) } }) {
    MoneyField(builder.currency, {
      label = "Required Buyer's Escrow on Agreement"
      value = builder.deposit
      onModified = { setBuilder(builder.copy(deposit = it)) }
    })
    HelpChip("listing-deposit", 22.px)
  }
  FlexRow({ style { marginBottom(paddingSm) } }) {
    DatePicker({
      label = "Closing Date"
      value = builder.closingDate
      onModified = { setBuilder(builder.copy(closingDate = it)) }
    })
    HelpChip("listing-closing-date", 22.px)
  }

  Row {
    RadioButton({
      label = "Would you like to withhold offers?"
      value = builder.withholdOffers
      popupRef = "listing-withholding-offers"
      onModified = { setBuilder(builder.copy(withholdOffers = it)) }
    })
  }

  if (builder.withholdOffers == true) {
    FlexRow {
      FlexColumn {
        Div({ classes(darkGreyText) }) {
          Text("Until when?")
        }
        DatePicker({
          label = "Hold offers until"
          value = builder.withholdDate
          onModified = { setBuilder(builder.copy(withholdDate = it)) }
        })
      }
    }
  }
}

@Composable
fun ListingDetailsDisplay(item: ListingDetails, currency: Currency) {
  FlatList(
    "Price: ${formatCurrency(item.price.core.value.get(), currency)}",
    item.deposit.core.value.getOrNull()?.let { "Escrow: ${formatCurrency(it, currency)}" },
    item.closingDate.core.value.getOrNull()?.let { "Closing Date: ${formatDate(it)}" }
  )
  if (item.offerWithholding != null && item.offerWithholding!!.until.core.value.isPresent()) {
    FlatList(
      "Withholding offers until ${formatDateTime(item.offerWithholding!!.until.core.value.get())}"
    )
  }
}
