package layout

import androidx.compose.runtime.Composable
import com.diyoffer.negotiation.ui.brand.LocalBrand
import common.Icon
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.px

@Composable
fun Logo(withBottomMargin: Boolean) {
  Icon(LocalBrand.current.logo, attrs = {
    style {
      height(50.px)
      if (withBottomMargin) {
        marginBottom(32.px)
      }
    }
  })
}
