package common

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.diyoffer.negotiation.messages.DiyImage
import com.diyoffer.negotiation.ui.icons.IconName
import dev.petuska.kmdc.dialog.Actions
import dev.petuska.kmdc.dialog.Content
import dev.petuska.kmdc.dialog.Header
import dev.petuska.kmdc.dialog.MDCDialog
import dev.petuska.kmdc.dialog.MDCDialogScope
import dev.petuska.kmdc.dialog.onClosed
import dev.petuska.kmdcx.icons.MDCIcon
import dev.petuska.kmdcx.icons.MDCIconSpan
import kotlinx.browser.document
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.right
import org.jetbrains.compose.web.css.top
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet
import style.DiyStyleSheet.Sizes.paddingSm
import style.DiyStyleSheet.onDarkBlue
import style.DiyStyleSheet.onLightGrey
import style.GridStyleSheet

@Suppress("LongParameterList")
@Composable
fun Dialog(
  open: Boolean,
  title: String,
  headerImg: DiyImage? = null,
  actionLabel: String = "Close",
  onAction: () -> Unit,
  onClose: () -> Unit,
  content: ContentBuilder<HTMLDivElement>,
) {
  val cleanUp: () -> Unit = {
    // https://github.com/SamProf/MatBlazor/issues/829 the mdc-dialog-scroll-lock is not correctly cleaned-up on close.
    // I'm not sure of the root cause but one fix documented in above link is to remove it ourselves.
    document.querySelector("body.mdc-dialog-scroll-lock")?.classList?.remove("mdc-dialog-scroll-lock")
  }

  MDCDialog(
    open = open,
    attrs = {
      onClosed {
        cleanUp()
        // if action is "close" then the dialog was closed by the user via the esc key or scrim (off dialog area)
        // in that case, we call `onClose` because we want to trigger the MVI input to close the dialog, BUT we
        // *do not* call it if action is null because that indicates we already closed it ourselves via the remove
        // icon onClick or the action button
        if (it.detail.action == "close") {
          onClose()
        }
      }
    }
  ) {
    Header(
      attrs = {
        style {
          marginTop(0.px)
          position(Position.Relative)
        }
      }
    ) {
      headerImg?.let { Img(it.src) { style { width(100.percent) } } }
      Icon(IconName.REMOVE) {
        style {
          position(Position.Absolute)
          top(29.px)
          right(29.px)
          width(14.px)
          cursor("pointer")
          property("z-index", "100")
        }
        onClick {
          cleanUp()
          onClose()
        }
      }
    }

    DialogBody(title, content = content)

    Actions(attrs = { style { padding(10.px, 24.px, 24.px) } }) {
      DarkBlueButton(attrs = {
        style { padding(14.px, 24.px) }
        onClick {
          cleanUp()
          onAction()
        }
      }) {
        Text(actionLabel)
      }
    }
  }
}

@Suppress("LongParameterList")
@Composable
fun ConfirmDialog(
  opened: Boolean,
  title: String,
  requireReason: Boolean = false,
  onConfirm: (String?) -> Unit,
  onCancel: () -> Unit,
  content: ContentBuilder<HTMLDivElement>,
) {
  val cleanUp: () -> Unit = {
    // https://github.com/SamProf/MatBlazor/issues/829 the mdc-dialog-scroll-lock is not correctly cleaned-up on close.
    // I'm not sure of the root cause but one fix documented in above link is to remove it ourselves.
    document.querySelector("body.mdc-dialog-scroll-lock")?.classList?.remove("mdc-dialog-scroll-lock")
  }

  val (reason, setReason) = remember { mutableStateOf<String?>(null) }
  MDCDialog(
    open = opened,
    attrs = {
      onClosed {
        cleanUp()
        onCancel()
      }
    }
  ) {
    DialogBody(title, content = content)
    if (requireReason) {
      Div({ style { padding(DiyStyleSheet.Sizes.padding) } }) {
        TextArea({
          label = "Reason"
          value = reason
          onModified = { setReason(it) }
        })
      }
    }
    Actions({ style { padding(10.px, 24.px, 24.px) } }) {
      FlexRow {
        ActionButton(
          disabled = requireReason && reason.isNullOrBlank(),
          activeClass = onDarkBlue,
          attrs = {
            style { padding(14.px, 24.px) }
            onClick {
              it.stopPropagation()
              cleanUp()
              onConfirm(reason)
            }
          }
        ) {
          Text("Confirm")
        }
        Button(attrs = {
          classes(onLightGrey)
          style { padding(14.px, 24.px) }
          onClick {
            it.stopPropagation()
            cleanUp()
            onCancel()
          }
        }) {
          Text("Cancel")
        }
      }
    }
  }
}

@Composable
fun MDCDialogScope.DialogBody(
  title: String?,
  titleIcon: MDCIcon? = null,
  content: ContentBuilder<HTMLDivElement>,
) {
  Content {
    Div({
      classes(GridStyleSheet.flex, GridStyleSheet.flexColumn)
      style { marginTop(paddingSm) }
    }) {
      if (title != null) {
        Div({
          classes(GridStyleSheet.flex, GridStyleSheet.alignItemsCenter)
          style {
            color(DiyStyleSheet.Colors.black)
            fontWeight(DiyStyleSheet.Weights.semiBold)
            fontSize(16.px)
            gap(10.px)
          }
        }) {
          titleIcon?.let { MDCIconSpan(it) }
          Text(title)
        }
      }
      Div(content = content)
    }
  }
}
