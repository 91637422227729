package forms.auth

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import app.softwork.routingcompose.Router
import app.softwork.routingcompose.navigate
import com.diyoffer.negotiation.auth.FirebaseAuth
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.auth.*
import com.diyoffer.negotiation.ui.login.SignInScreenEventHandler
import com.diyoffer.negotiation.ui.login.SignUpScreenContract.Inputs
import com.diyoffer.negotiation.ui.login.SignUpScreenEventHandler
import common.ActionButton
import common.Checkbox
import common.FlexRow
import common.GoBackButton
import common.Row
import common.TextField
import common.TextFieldPassword
import common.ValidationMessage
import components.DiySelect
import components.SigningMode
import components.TermsOfService
import components.WithLoadingOverlay
import kotlinx.coroutines.launch
import layout.Logo
import model.LoginAction
import model.nextActionParam
import org.jetbrains.compose.web.attributes.AutoComplete
import org.jetbrains.compose.web.attributes.autoComplete
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Form
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.rememberInstance
import style.DiyStyleSheet
import style.DiyStyleSheet.buttonLink
import style.GridStyleSheet
import style.GridStyleSheet.flex
import style.GridStyleSheet.justifyContentCenter
import style.SignFormStyleSheet
import vm.login.SignInViewModel
import vm.login.SignInViewModelConfiguration
import vm.login.SignUpViewModel
import vm.login.SignUpViewModelConfiguration

@Suppress("LongMethod")
@Composable
fun SignUp(nextAction: String? = null) {
  val router = Router.current
  val scope = rememberCoroutineScope()
  val vmConfig by rememberInstance<SignUpViewModelConfiguration>()
  val firebaseAuth by rememberInstance<FirebaseAuth>()
  val vm = remember(scope) {
    SignUpViewModel(
      config = vmConfig,
      eventHandler = SignUpScreenEventHandler(
        onSuccess = { router.navigate(nextAction ?: "/home") }
      ),
      viewModelCoroutineScope = scope
    )
  }

  val state by vm.observeStates().collectAsState()

  val vmSignInConfig by rememberInstance<SignInViewModelConfiguration>()
  val vmSignIn = remember(scope) {
    SignInViewModel(
      config = vmSignInConfig,
      eventHandler = SignInScreenEventHandler(
        onSuccess = { router.navigate(nextAction ?: "/home") }
      ),
      viewModelCoroutineScope = scope,
      firebaseAuth = firebaseAuth,
    )
  }

  val signInState by vmSignIn.observeStates().collectAsState()

  Div({
    classes(SignFormStyleSheet.container)
  }) {
    Row({
      classes(flex, justifyContentCenter)
    }) {
      Logo(true)
    }

    WithLoadingOverlay(state.loadingState.isLoading()) {
      Form {
        Row {
          DiySelect(
            "Country",
            Country.values().toList(),
            initialSelected = state.country,
          ) {
            vm.trySend(Inputs.CountryChanged(it!!))
          }
        }
        Div({
          classes("separator")
        })
        Row {
          TextField(
            opts = {
              label = "Email"
              value = state.email
              onModified = { vm.trySend(Inputs.EmailChanged(it)) }
            },
            attrs = {
              autoComplete(AutoComplete.email)
            }
          )
        }
        Row {
          TextFieldPassword(opts = {
            label = "Password"
            value = state.password
            onModified = { vm.trySend(Inputs.PasswordChanged(it)) }
          }) {
            autoComplete(AutoComplete.newPassword)
          }
        }
        Row {
          TextFieldPassword(opts = {
            label = "Confirm Password"
            value = state.confirmPassword
            onModified = { vm.trySend(Inputs.ConfirmPasswordChanged(it)) }
            onKeyEnter = {
              vm.trySend(Inputs.SignUpWithEmailAndPasswordClicked)
            }
          }) {
            autoComplete(AutoComplete.newPassword)
          }
        }
        Checkbox(
          checked = state.acceptUserAgreementTerms,
          onChange = { vm.trySend(Inputs.AcceptUserAgreementTerms(it)) }
        ) {
          TermsOfService(SigningMode.SIGN_UP, false)
        }
        Checkbox(
          checked = state.acceptMarketingContent,
          onChange = { vm.trySend(Inputs.AcceptMarketingContent(it)) }
        ) {
          Text("I would like to receive promotional emails and offers which may be of interest to me.")
        }
      }

      // TODO place email and password and confirm errors with the field, set the fields to an error state
      ValidationMessage(state.emailError)
      ValidationMessage(state.passwordError)
      ValidationMessage(state.confirmPasswordError)
      ValidationMessage(state.acceptUserAgreementTermsError)
      ValidationMessage(state.error)

      Row {
        ActionButton(
          attrs = {
            classes(SignFormStyleSheet.authActionButton)
            style {
              margin(12.px, 0.px)
            }
            onClick {
              vm.trySend(Inputs.SignUpWithEmailAndPasswordClicked)
            }
          }
        ) {
          Text("Sign up")
        }
      }

      Row({
        classes(flex, justifyContentCenter)
        style { marginTop(16.px) }
      }) {
        Text("Already have an account?")
        Span(attrs = {
          classes(buttonLink)
          style { marginLeft(5.px) }
          onClick { router.navigate("/auth/sign-in", nextActionParam(nextAction)) }
        }) {
          Text("Sign in")
        }
      }

      Div({
        classes("separator")
      }) {
        Text("Or")
      }

      Div({
        classes(flex, GridStyleSheet.flexColumn)
        style { gap(16.px) }
      }) {
        SocialButton(
          "Google",
          LoginAction.UP,
          signInState.linkCredentials?.currentProviderId == ProviderId.GOOGLE
        ) {
          onClick {
            scope.launch {
              vm.sendAndAwaitCompletion(Inputs.SignUpWithSocial)
              vmSignIn.obtainSocialAuthCredentialsAndTriggerSignIn(
                ProviderId.GOOGLE,
                true,
                signInState.linkCredentials?.loginHint,
              )
            }
          }
        }
        SocialButton(
          "Facebook",
          LoginAction.UP,
          signInState.linkCredentials?.currentProviderId == ProviderId.FACEBOOK
        ) {
          onClick {
            scope.launch {
              vm.sendAndAwaitCompletion(Inputs.SignUpWithSocial)
              vmSignIn.obtainSocialAuthCredentialsAndTriggerSignIn(
                ProviderId.FACEBOOK,
                true,
                signInState.linkCredentials?.loginHint,
              )
            }
          }
        }
      }

      Row({
        classes(flex, justifyContentCenter)
        style { marginTop(16.px) }
      }) {
        ValidationMessage(signInState.socialError)
      }
    }

    FlexRow({
      style {
        paddingTop(DiyStyleSheet.Sizes.padding)
        justifyContent(JustifyContent.Center)
      }
    }) {
      Span { TermsOfService(SigningMode.SIGN_UP, true) }
    }

    Row({
      classes(flex, justifyContentCenter)
    }) {
      GoBackButton { style { paddingBottom(0.px) } }
    }
  }
}
