package common

import androidx.compose.runtime.Composable
import com.benasher44.uuid.uuid4
import com.diyoffer.negotiation.common.Ignore
import core.Builder
import dev.petuska.kmdc.textfield.MDCTextField
import dev.petuska.kmdc.textfield.MDCTextFieldType
import dev.petuska.kmdcx.icons.MDCIcon
import dev.petuska.kmdcx.icons.MDCIconSpan
import kotlinx.browser.document
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.opacity
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.right
import org.jetbrains.compose.web.css.top
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.DateInput
import org.jetbrains.compose.web.dom.DateTimeLocalInput
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import style.InputDateStyleSheet
import web.errors.DOMException

// I would like to use a library here but I wasn't able to embed js-datepicker, using native date input
@Composable
fun DatePicker(
  opts: Builder<InputOptions<LocalDate>>,
  attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Style(InputDateStyleSheet)
  val id = "Datepicker-${uuid4()}"
  val options = InputOptions<LocalDate>().apply { opts(this) }
  val dateStr = (options.value ?: "").toString()

  FlexRow(
    attrs = {
      style {
        flexGrow(1)
        margin(0.px)
      }
      attrs?.invoke(this)
    },
    content = {
      MDCTextField(
        value = dateStr,
        label = options.label,
        type = MDCTextFieldType.Outlined,
        disabled = options.disabled,
        attrs = {
          onClick {
            if (it.isTrusted) showPicker(id)
          }
          onFocus {
            if (it.isTrusted) showPicker(id)
          }
        },
        trailingIcon = {
          MDCIconSpan(icon = MDCIcon.CalendarToday) {
            style {
              position(Position.Absolute)
              top(8.px)
              right(8.px)
            }
          }
        }
      )

      // We need a DateInput on the page to make the calendar popup correctly
      // If we hidden() or display:none, calendar pops up top left of the screen
      DateInput {
        id(id)
        style {
          opacity(0)
          width(0.px)
          height(0.px)
          position(Position.Absolute)
        }
        value(dateStr)
        onChange {
          options.onModified(if (it.value.isBlank()) null else LocalDate.parse(it.value))
        }
      }
    },
  )
}

@Composable
fun DateTimePicker(
  opts: Builder<InputOptions<LocalDateTime>>,
  attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val options = InputOptions<LocalDateTime>().apply { opts.invoke(this) }
  Style(InputDateStyleSheet)
  val idDt = "datetimepicker-${uuid4()}"
  val idTxt = "text-$idDt"
  val initialValue = options.value?.let { formatDate(it.toString()) } ?: ""

  FlexRow(
    attrs = {
      style {
        flexGrow(1)
        margin(0.px)
      }
      attrs?.invoke(this)
    },
    content = {
      DateTimPickerContent(options, initialValue, idTxt, idDt)
    },
  )
}

@Composable
private fun DateTimPickerContent(
  options: InputOptions<LocalDateTime>,
  initialValue: String,
  idTxt: String,
  idDt: String,
) {
  MDCTextField(
    value = initialValue,
    label = options.label,
    type = MDCTextFieldType.Outlined,
    disabled = options.disabled,
    attrs = {
      id(idTxt)
      onClick {
        if (it.isTrusted) showPicker(idDt)
      }
      onFocus {
        if (it.isTrusted) showPicker(idDt)
      }
      onBlur {
        val dateStr = (document.getElementById(idTxt) as HTMLInputElement).getAttribute("isoDate")
        options.onModified(if (dateStr.isNullOrBlank()) null else LocalDateTime.parse(dateStr))
      }
    },
    trailingIcon = {
      MDCIconSpan(icon = MDCIcon.CalendarToday) {
        style {
          position(Position.Absolute)
          top(8.px)
          right(8.px)
        }
      }
    }
  )

  DateTimeLocalInput {
    id(idDt)
    style {
      opacity(0)
      width(0.px)
      height(0.px)
      position(Position.Absolute)
    }
    value(initialValue)
    onInput {
      (document.getElementById(idTxt) as HTMLInputElement).value = formatDate(it.value)
      (document.getElementById(idTxt) as HTMLInputElement).setAttribute("isoDate", it.value)
    }
  }
}

private fun showPicker(id: String) {
  @Suppress("SwallowedException")
  try {
    document.getElementById(id).asDynamic().showPicker()
  } catch (e: DOMException) {
    // sometimes a DOMException is still thrown even though we check trusted before calling this
    Ignore
  }
}

@Suppress("UNUSED_PARAMETER")
private fun formatDate(dateStr: String): String = js("new Date(dateStr).toLocaleString()") as String
