package forms

import androidx.compose.runtime.Composable
import androidx.compose.web.events.SyntheticMouseEvent
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.brand.LocalBrand
import com.diyoffer.negotiation.ui.icons.IconName
import com.diyoffer.negotiation.ui.offer.OfferContactsContract.Inputs
import com.diyoffer.negotiation.ui.offer.OfferContactsContract.State
import common.FlatList
import common.FlexRow
import common.Icon
import common.Row
import common.TextBold
import common.TextField
import common.ValidationMessage
import components.EmailContactEdit
import components.snackbar.Snackbar
import dev.petuska.kmdcx.icons.MDCIcon
import dev.petuska.kmdcx.icons.MDCIconSpan
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import style.DiyStyleSheet
import style.DiyStyleSheet.Sizes.paddingSm
import vm.offer.OfferContactsViewModel

@Composable
fun OfferBuyersEdit(vm: OfferContactsViewModel, state: State) {
  state.contactsUI.mapIndexed { index, contact ->
    contact?.let {
      BuyerContactItem(index, vm, state)
    }
  }

  AddMoreContactButton { vm.trySend(Inputs.AddContact) }

  state.error?.let {
    Snackbar(it) { vm.trySend(Inputs.ClearErrors) }
  }
}

@Composable
fun AddMoreContactButton(onClick: (SyntheticMouseEvent) -> Unit) {
  Row({ style { marginTop(8.px) } }) {
    Button({
      classes(DiyStyleSheet.onLightBlue, DiyStyleSheet.button)
      style {
        width(125.px)
        fontWeight(DiyStyleSheet.Weights.darkNormal)
        fontSize(14.px)
      }
      this.onClick {
        it.stopPropagation()
        onClick(it)
      }
    }) {
      Text("+ Add more")
    }
  }
}

@Composable
fun BuyerContactItem(
  index: Int,
  vm: OfferContactsViewModel,
  state: State,
) {
  val contactUI = state.contactsUI[index]!!
  Row({
    style {
      paddingLeft(16.px)
      marginBottom(24.px)
      borderRadius(0.px)
      property("border-left", "2px solid ${DiyStyleSheet.Colors.green}")
    }
  }) {
    FlexRow({ style { justifyContent(JustifyContent.SpaceBetween) } }) {
      Div({
        style {
          color(DiyStyleSheet.Colors.darkGrey)
          fontWeight(DiyStyleSheet.Weights.darkNormal)
        }
      }) {
        Icon(IconName.PERSON) { style { paddingRight(5.px) } }
        val label = if (index == 0) LocalBrand.current.buyerSectionFirst else LocalBrand.current.buyerFullNameTextSubsequent
        Text("$label ${if (index == 0) "" else index}")
      }
      if ((state.contactsUI.size) > 1) {
        MDCIconSpan(icon = MDCIcon.Delete) {
          style { cursor("pointer") }
          onClick {
            it.stopPropagation()
            vm.trySend(Inputs.RemoveContact(index))
          }
        }
      }
    }

    val buyerFullNameText =
      if (index == 0) LocalBrand.current.buyerFullNameTextFirst else LocalBrand.current.buyerFullNameTextSubsequent

    Row {
      TextField(opts = {
        label = buyerFullNameText
        value = contactUI.name
        onModified = { vm.trySend(Inputs.UpdateName(index, it)) }
      })
    }

    Row({ style { marginBottom(paddingSm) } }) {
      TextBold(
        "Notification methods (offers, counters):"
      )
    }

    Row {
      EmailContactEdit(index, vm, contactUI)
    }

    contactUI.error?.let {
      ValidationMessage(it)
    }
  }
}

@Composable
fun OfferBuyersDisplay(contacts: OfferContacts?) {
  contacts?.buyers?.contacts?.contactMethodsOf<ContactMethod.Email>()?.map { (method, contact) ->
    Row {
      FlatList(
        contact.name,
        "${method.email} (${if (method.verified.bool()) "Verified" else "Unverified"})"
      )
    }
  }
}
