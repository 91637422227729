package pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import app.softwork.routingcompose.RouteBuilder
import com.diyoffer.negotiation.ui.brand.LocalBrand
import com.diyoffer.negotiation.ui.home.SellerHomeScreenContract.Inputs
import com.diyoffer.negotiation.ui.state.LoadingState
import common.FlexRow
import components.TabContent
import components.TabSheet
import components.listing.SideBarListings
import dev.petuska.kmdcx.icons.MDCIcon
import dev.petuska.kmdcx.icons.MDCIconSpan
import dev.petuska.kmdcx.icons.MDCIconType
import layout.AppContainer
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.rememberInstance
import style.DiyStyleSheet
import style.GridStyleSheet
import style.OfferListStyleSheet
import style.WhatTodoStyleSheet
import vm.home.SellerHomeScreenViewModel
import vm.home.SellerHomeScreenViewModelConfiguration
import vm.login.UserViewModel

@Composable
fun RouteBuilder.SellerHomePage(userVm: UserViewModel) {
  Style(WhatTodoStyleSheet)
  Style(OfferListStyleSheet)

  val scope = rememberCoroutineScope()
  val vmConfig by rememberInstance<SellerHomeScreenViewModelConfiguration>()
  val vm = remember(scope) {
    SellerHomeScreenViewModel(config = vmConfig, viewModelCoroutineScope = scope)
  }
  val state by vm.observeStates().collectAsState()

  LaunchedEffect(Unit) {
    vm.send(Inputs.Fetch)
  }

  AppContainer(
    userVm,
    sideBarContent = { SideBarListings() },
    informationBarText = "Welcome to ${LocalBrand.current.productShortName}! Create terms of sale, publish them, " +
      "and we'll keep you up-to-date with a customized checklist and all your offers.",
  ) {
    Div({ classes(GridStyleSheet.flexColumn) }) {
      TabSheet(
        tabs = listOf(
          TabContent(
            route = "/home/offers",
            buttonContent = { currentTab, onClick ->
              SellerTabButton(
                "All Offers",
                currentTab,
                state.offerCount,
                state.offersLoadingState,
                onClick,
              )
            },
          ) { OfferListPage(userVm) },
          TabContent(
            route = "/home/checklist",
            buttonContent = { currentTab, onClick ->
              SellerTabButton(
                "What's next?",
                currentTab,
                state.checklistCount(),
                state.checklistLoadingState(),
                onClick,
              )
            },
          ) { ChecklistArea() },
        ),
        tabRowAttrs = {
          style { gap(32.px) }
        },
        initialRoute = "/home/offers",
      )
    }
  }
}

@Composable
private fun SellerTabButton(
  label: String,
  selected: Boolean,
  badgeCount: Int? = null,
  loadingState: LoadingState,
  onClick: () -> Unit,
) {
  FlexRow(attrs = {
    classes("pointer")
    style {
      gap(0.px)
      borderRadius(0.px)
      property("border-bottom", "3px solid ${if (selected) DiyStyleSheet.Colors.green else Color.transparent}")
    }
    onClick { onClick() }
  }) {
    H2({ style { property("margin", "6px 0") } }) { Text(label) }
    Span({ classes(OfferListStyleSheet.badge) }) {
      when (loadingState) {
        LoadingState.FETCHING, LoadingState.NOT_LOADED -> {
          MDCIconSpan(
            type = MDCIconType.TwoTone,
            icon = MDCIcon.Loop,
          )
        }
        // for now, we don't have an ux state for unauthorized or error
        LoadingState.READY,
        LoadingState.UNAUTHORIZED,
        LoadingState.ERROR,
        -> {
          badgeCount?.let {
            Text(it.toString())
          }
        }
      }
    }
  }
}
