package com.diyoffer.negotiation

import kotlin.Long
import kotlin.String

public object BuildConfig {
  public const val APP_NAME: String = "shared"

  public const val BUILD_VERSION: String = "1.0-SNAPSHOT"

  public const val GIT_VERSION_DESCRIBE: String = "65ed2d3"

  public const val GIT_VERSION: String = "65ed2d3e1e6574ed01bd84e33266f7437ccd2160"

  public const val BUILD_TIME: Long = 1711459776767L
}
