package builders

import com.diyoffer.negotiation.model.*
import kotlinx.datetime.Clock

data class AssumableContractListBuilder(
  val currency: Currency,
  val assumableContractsText: String,
  val assumableContractList: List<AssumableContract>? = null,
) : IBuilder<ListingAssumableContracts> {
  override fun hydrate(item: ListingAssumableContracts?) = item?.let { assumableContract ->
    copy(
      assumableContractList = assumableContract.contracts.mapNotNull {
        it.core.value.getOrNull()
      }
    )
  } ?: AssumableContractListBuilder(currency, assumableContractsText)

  override fun build(): BuildResult<ListingAssumableContracts> = validateAndBuild {
    ListingAssumableContracts(
      contracts = assumableContractList?.map {
        Auditable.Core(
          Optional.of(it),
          Clock.System.now().lowRes(),
          message = "Created by UI V1. Support for auditable coming."
        )
      } ?: listOf()
    )
  }

  private fun validateAndBuild(onValid: () -> ListingAssumableContracts): BuildResult<ListingAssumableContracts> {
    val warnings = mutableListOf<String>()

    // Each item have run their own builder, so we are just validating at the list level
    if (assumableContractList.isNullOrEmpty()) {
      val contractLabel = assumableContractsText.lowercase()
      warnings.add("You are not disclosing any ${contractLabel.dropLast(1)}.")
    }

    return buildResult(warnings = warnings, onValid = onValid)
  }
}
