@file:Suppress("MagicNumber")

package pages

import androidx.compose.runtime.Composable
import app.softwork.routingcompose.RouteBuilder
import app.softwork.routingcompose.Router
import com.diyoffer.negotiation.analytics.AnalyticsClient
import com.diyoffer.negotiation.analytics.AnalyticsEvent
import com.diyoffer.negotiation.common.Ignore
import com.diyoffer.negotiation.messages.YoutubeVideo
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.brand.Brand
import com.diyoffer.negotiation.ui.brand.LocalBrand
import common.ActionButton
import common.FlexRow
import common.TextBold
import components.Loading
import components.WelcomeCard
import components.lightbox.YoutubeEmbed
import components.lightbox.YoutubeLink
import layout.AppContainer
import model.Guides
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.rememberInstance
import style.DiyStyleSheet
import vm.login.UserViewModel
import web.window.WindowTarget
import web.window.window

@Composable
fun RouteBuilder.HomePage(userVm: UserViewModel, user: SessionUser) {
  when (user) {
    SessionUser.NotLoaded -> SellerLoadingHomePage(userVm)
    else -> when (user.role) {
      UserRole.SELLER -> SellerHomePage(userVm)
      else -> VisitorHomePage(userVm)
    }
  }
}

@Composable
private fun SellerLoadingHomePage(userVm: UserViewModel) {
  AppContainer(
    userVm,
  ) {
    Loading("We're loading your account...")
  }
}

@Composable
private fun VisitorHomePage(userVm: UserViewModel) {
  val router = Router.current

  val analyticsClient by rememberInstance<AnalyticsClient>()

  AppContainer(
    userVm,
    informationBarText = "Welcome! One of the suggested options below should get you started.",
  ) {
    Div({
      classes(DiyStyleSheet.mainForm)
    }) {
      Div {
        TextBold(
          "Welcome to ${LocalBrand.current.productName}"
        ) {
          style {
            color(DiyStyleSheet.Colors.darkBlue)
            paddingBottom(25.px)
            property("font-size", "22px")
          }
        }
      }
      when (LocalBrand.current) {
        Brand.DIYoffer -> {
          WelcomeBoxes(router, analyticsClient)
          FlexRow(attrs = {
            style {
              marginTop(30.px)
            }
          }) {
            YoutubeEmbed(YoutubeVideo.OVERVIEW_HIGH_LEVEL)
          }
        }
        Brand.ReAgent -> Ignore
      }
    }
  }
}

@Composable
private fun WelcomeBoxes(
  router: Router,
  analyticsClient: AnalyticsClient,
) {
  FlexRow(attrs = {
    style {
      alignItems(AlignItems.Stretch)
    }
  }) {
    PublishListingTermsCard(router)
    BuyerGuideCard(analyticsClient)
    SellerGuideCard(analyticsClient)
  }
}

@Composable
private fun PublishListingTermsCard(router: Router) {
  WelcomeCard(
    "Sell on ${LocalBrand.current.productShortName} by publishing listing terms",
    message = {
      when (LocalBrand.current) {
        Brand.DIYoffer -> {
          Span {
            Text(
              "Publish your listing terms immediately! We will guide you every step the way. Watch our video tutorials on "
            )
            YoutubeLink(YoutubeVideo.SELLER_LISTING, "publishing terms")
            Text(".")
          }
        }

        Brand.ReAgent -> {
          Span {
            Text(
              "Publish listing terms and accept offers on The REAGENT Offer Management System."
            )
          }
        }
      }
    },
    highlightLabel = "Recommended",
  ) {
    ActionButton(attrs = {
      style { padding(14.px, 24.px) }
      onClick { router.navigate("/listing/create", hide = true) }
    }) {
      Text("Create listing terms")
    }
  }
}

@Composable
private fun BuyerGuideCard(analyticsClient: AnalyticsClient) {
  WelcomeCard(
    title = "Read our Buyer Guide",
    message = {
      Span {
        Text(
          "Read our detailed buyer guide to understand how to use the platform as a buyer, " +
            "and watch our video tutorial on "
        )
        YoutubeLink(YoutubeVideo.BUYER_OFFER, "making an offer")
        Text(".")
      }
    },
  ) {
    ActionButton(
      attrs = {
        style { padding(14.px, 24.px) }
        onClick {
          analyticsClient.logEvent(AnalyticsEvent.BuyerGuideClicked)
          window.open(Guides.BUYER_GUIDE, WindowTarget._blank)
        }
      }
    ) {
      Text("Start reading")
    }
  }
}

@Composable
private fun SellerGuideCard(analyticsClient: AnalyticsClient) {
  WelcomeCard(
    title = "Read our Seller Guide",
    message = {
      Span {
        Text(
          "Read our detailed seller guide to understand how to use the platform as a seller, " +
            "and watch our video tutorials on "
        )
        YoutubeLink(YoutubeVideo.SELLER_LISTING, "creating a listing")
        Text(" and on ")
        YoutubeLink(YoutubeVideo.SELLER_NEGOTIATING, "negotiating a deal")
        Text(".")
      }
    },
  ) {
    ActionButton(
      attrs = {
        style { padding(14.px, 24.px) }
        onClick {
          analyticsClient.logEvent(AnalyticsEvent.SellerGuideClicked)
          window.open(Guides.SELLER_GUIDE, WindowTarget._blank)
        }
      }
    ) {
      Text("Start reading")
    }
  }
}
