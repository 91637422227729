package forms

import androidx.compose.runtime.Composable
import builders.OfferBuyerConfirmationBuilder
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.brand.LocalBrand
import common.FlatListContent
import common.RadioButton
import common.Row
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import style.DiyStyleSheet.Colors.green
import style.DiyStyleSheet.Colors.red

@Composable
fun OfferBuyerConfirmationEdit(
  builder: OfferBuyerConfirmationBuilder,
  setBuilder: (OfferBuyerConfirmationBuilder) -> Unit,
) {
  val buyerAgeConfirmText = LocalBrand.current.buyerAgeConfirmText

  Row({ style { marginBottom(14.px) } }) {
    RadioButton({
      label = buyerAgeConfirmText
      value = builder.over18
      onModified = { setBuilder(builder.copy(over18 = it)) }
    })
  }

  val buyerLegalConfirmText = LocalBrand.current.buyerLegalConfirmText

  Row({ style { marginBottom(14.px) } }) {
    RadioButton({
      label = buyerLegalConfirmText
      value = builder.userCertifiedLegalAuthority
      onModified = { setBuilder(builder.copy(userCertifiedLegalAuthority = it)) }
    })
  }
}

@Composable
fun OfferBuyerConfirmationDisplay(item: OfferBuyerConfirmation) {
  val over18 = item.over18.core.value.get()
  val legalAuthority = item.userCertifiedLegalAuthority.core.value.get()
  FlatListContent(
    { CheckOrXMarkText("I'm over 18", over18) },
    { CheckOrXMarkText("I have legal authority to represent the buyer(s)", legalAuthority) },
  )
}

@Composable
fun CheckOrXMarkText(text: String, bool: Boolean) {
  Text(text)
  CheckOrXMark(bool)
}

@Composable
fun CheckOrXMark(bool: Boolean) {
  if (bool) {
    Span(attrs = {
      style {
        color(green)
        marginLeft(5.px)
      }
    }) { Text("✓",) }
  } else {
    Span(attrs = {
      style {
        color(red)
        marginLeft(5.px)
      }
    }) { Text("✗",) }
  }
}
