package forms

import androidx.compose.runtime.Composable
import builders.OfferAssumableContractsBuilder
import com.diyoffer.negotiation.common.formatCurrency
import com.diyoffer.negotiation.common.formatDate
import com.diyoffer.negotiation.common.replaceAt
import com.diyoffer.negotiation.common.safeCast
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.brand.LocalBrand
import common.FlatList
import common.Row
import components.color
import components.icon
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import style.DiyStyleSheet
import style.GridStyleSheet
import style.GridStyleSheet.flex

// PO: This file will be updated to NegociatedTerm UI framework in next commit.
@Composable
fun OfferAssumableContractListEdit(
  builder: OfferAssumableContractsBuilder,
  setBuilder: (OfferAssumableContractsBuilder) -> Unit,
) {
  builder.assumableContractBuilders?.mapIndexed { idx, b ->
    val contract = b.term.currentValue.get()
    NegotiableItemInteract(
      optionKey = contract.contract,
      predefinedTitle = (contract.contract as? ContractOption)?.title,
      builder = b,
      setBuilder = {
        setBuilder(
          builder.copy(
            assumableContractBuilders = builder.assumableContractBuilders!!.replaceAt(idx, it)
          )
        )
      }
    ) {
      contract.contractDetails.safeCast<AssumableContractDetails.HasBuyout>()?.buyoutFee?.let {
        ItemDisplay("Outstanding Balance", formatCurrency(it, builder.currency))
      }
      contract.contractDetails.expiry?.let {
        ItemDisplay("Expiration Date", formatDate(it))
      }
      ItemDisplay(
        "Monthly Payment",
        "${formatCurrency(contract.contractDetails.monthlyFee, builder.currency)} per month"
      )
    }
  }
}

@Composable
fun ItemDisplay(label: String, value: String) {
  Div({
    classes(flex, GridStyleSheet.alignItemsStart)
    style {
      marginTop(4.px)
      marginBottom(8.px)
    }
  }) {
    Div({
      classes(GridStyleSheet.flexColumn, GridStyleSheet.col6)
    }) {
      Span({
        style {
          color(DiyStyleSheet.Colors.darkGrey)
          fontWeight(DiyStyleSheet.Weights.darkNormal)
        }
      }) {
        Text(label)
      }
      Span({
        style { color(DiyStyleSheet.Colors.grey) }
      }) {
        Text(value)
      }
    }
  }
}

@Composable
fun OfferAssumableContractListDisplay(items: OfferAssumableContracts, currency: Currency) {
  if (items.contracts.isEmpty()) {
    val contractLabel = LocalBrand.current.assumableContractText
    FlatList("No $contractLabel was provided.")
  } else {
    items.contracts.map {
      Row {
        AssumableContractDisplay(
          item = it.currentValue.get(),
          currency = currency,
          icon = it.state.icon(),
          iconColor = it.state.color(),
          lineThrough = it.state == NegotiatedTerm.State.REMOVED
        )
      }
    }
  }
}
