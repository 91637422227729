package common

import androidx.compose.runtime.Composable
import com.diyoffer.negotiation.ui.icons.IconName
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Img
import org.w3c.dom.HTMLImageElement

@Composable
fun Icon(
  iconName: IconName,
  attrs: AttrBuilderContext<HTMLImageElement>? = null,
) {
  val iconUrl = "images/" + iconName.name.lowercase().replace("_", "-") + ".svg"
  Img(iconUrl) {
    attrs?.invoke(this)
  }
}
