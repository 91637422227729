package components.snackbar

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberUpdatedState
import co.touchlab.kermit.Logger
import com.diyoffer.negotiation.rpcs.IUpdateCheckRpcService
import com.diyoffer.negotiation.services.tryRpc
import com.diyoffer.negotiation.ui.brand.LocalBrand
import com.diyoffer.negotiation.version.APPLICATION_VERSION
import dev.petuska.kmdc.snackbar.Action
import dev.petuska.kmdc.snackbar.Actions
import dev.petuska.kmdc.snackbar.Label
import dev.petuska.kmdc.snackbar.MDCSnackbar
import dev.petuska.kmdc.snackbar.MDCSnackbarType
import dev.petuska.kmdc.snackbar.onClosed
import kotlinx.browser.window
import kotlinx.coroutines.delay
import kotlinx.coroutines.isActive
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import org.kodein.di.compose.rememberInstance
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

@Composable
fun UpdateCheckSnackbar() {
  val clock by rememberInstance<Clock>()
  val updateCheckRpcService by rememberInstance<IUpdateCheckRpcService>()
  val (open, setOpen) = remember { mutableStateOf(false) }
  val (seen, setSeen) = remember { mutableStateOf(null as SeenVersion?) }
  val isOpen by rememberUpdatedState(newValue = open)
  val isSeen by rememberUpdatedState(newValue = seen)

  MDCSnackbar(
    open = open,
    type = MDCSnackbarType.Leading,
    closeOnEscape = true,
    timeoutMs = null, // null means indefinite
    attrs = {
      onClosed {
        setOpen(false)
      }
    }
  ) {
    Label("A new version of ${LocalBrand.current.productName} is available")
    Actions {
      Action("Refresh", attrs = {
        onClick {
          window.location.reload()
        }
      })
    }
  }

  LaunchedEffect(APPLICATION_VERSION) {
    fun SeenVersion?.isExpired() = this == null || at < clock.now() - 5.minutes

    while (coroutineContext.isActive) {
      delay(10.seconds)
      if (isOpen || !isSeen.isExpired()) {
        continue
      } else {
        val onException: suspend (String, Exception) -> Unit =
          { _, e -> Logger.w(e) { "Unable to obtain latest app version" } }
        tryRpc(onException = onException) {
          val currentVersion = updateCheckRpcService.currentVersion()
          if (APPLICATION_VERSION != currentVersion) {
            setSeen(SeenVersion(currentVersion, clock.now()))
            setOpen(true)
          }
        }
      }
    }
  }
}

data class SeenVersion(
  val version: String,
  val at: Instant,
)
