package com.diyoffer.negotiation.common

import com.diyoffer.negotiation.model.*

/**
 * Format a currency according to the current system's locale information, always using the narrow symbol
 * for currency to avoid any confusion due to user's that have incorrect browser settings — this is unfortunately
 * common in Canada where many user's browsers are configured with `en-US`.
 *
 * @param money The money value to be formatted.
 * @param currency The currency to be used for formatting.
 * @return A string representing the formatted currency.
 */
fun formatCurrency(money: Money?, currency: Currency): String =
  formatCurrencyNarrowSymbol(money, currency, currentSystemPrimaryLocale())

/**
 * When we have a jurisdiction, we use its locale (with region) to format the currency.
 *
 * This is useful for the backend where it is important that if a listing currency is in CAD, but the listing
 * is in the US, we would want the currency symbols to be explicitly formatted as `CA$`. Normally, the region
 * will match the currency so the narrow symbol will be used.
 */
fun formatCurrency(money: Money?, currency: Currency, jurisdiction: Jurisdiction): String =
  formatCurrencySymbol(money, currency, jurisdiction.locale())

internal expect fun formatCurrencySymbol(money: Money?, currency: Currency, locale: String): String

internal expect fun formatCurrencyNarrowSymbol(money: Money?, currency: Currency, locale: String): String
