package com.diyoffer.negotiation.model.rpcs

/**
 * For use on the client side, to convert a backend [RpcError] back into an exception.
 */
sealed class BackendServiceException(message: String? = null) : Exception(message)

data object UnauthorizedServiceException : BackendServiceException()
data object BackendTimeoutServiceException : BackendServiceException("The system is unreachable at the moment.")
class UnexpectedErrorServiceException(
  val errorId: String,
  message: String,
  val stackTrace: String,
) : BackendServiceException(message)

fun RpcError.toBackendServiceException(): BackendServiceException = when (this) {
  RpcError.Unauthorized -> UnauthorizedServiceException
  RpcError.BackendTimeout -> BackendTimeoutServiceException
  is RpcError.UnexpectedError -> UnexpectedErrorServiceException(
    errorId = errorId,
    message = message,
    stackTrace = stackTrace,
  )
}
