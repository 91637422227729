package com.diyoffer.negotiation.environment

import com.diyoffer.negotiation.ui.brand.Brand
import org.w3c.dom.Location

/**
 * Map a hosted location to an [Env]. This only differentiates between [Env.PROD] and [Env.TEST] -- it is assumed
 * that the [Env.DEV] environment is determined via Webpack development mode and `meta.env`.
 */
fun Location.mapToEnv() = when (hostname) {
  in productionHosts -> Env.PROD
  in testHosts -> Env.TEST
  else -> Env.PROD
}

fun Location.isHosted() = isHosted(hostname)

fun Location.mapToBrand() = when {
  isDiyOffer(hostname) -> Brand.DIYoffer
  isReagent(hostname) -> Brand.ReAgent
  else -> null
}
