@file:Suppress("MagicNumber")

package pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import app.softwork.routingcompose.Router
import builders.AssumableContractListBuilder
import builders.ChattelsIncludedListBuilder
import builders.FixturesExcludedListBuilder
import builders.ListingBindingAgreementTermsBuilder
import builders.ListingExternalLinksBuilder
import builders.ListingPriceAndClosingBuilder
import builders.ListingPropertyDetailsBuilder
import builders.ListingPropertyOwnersBuilder
import builders.ViewModelBuilder
import com.diyoffer.negotiation.common.services.listings.buildConditionFilters
import com.diyoffer.negotiation.messages.CommonMessages
import com.diyoffer.negotiation.messages.YoutubeVideo
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.client.*
import com.diyoffer.negotiation.ui.brand.Brand.*
import com.diyoffer.negotiation.ui.brand.LocalBrand
import com.diyoffer.negotiation.ui.condition.ConditionListContract
import com.diyoffer.negotiation.ui.condition.getTitle
import com.diyoffer.negotiation.ui.listing.ListingEditScreenContract.Inputs
import com.diyoffer.negotiation.ui.listing.ListingEditScreenContract.State
import com.diyoffer.negotiation.ui.listing.ListingEditScreenEventHandler
import com.diyoffer.negotiation.ui.state.LoadingState
import common.ActionButton
import common.FlexRow
import components.ChangeLoginStateButton
import components.DetailContainer
import components.DetailStatus
import components.ReturnButton
import components.lightbox.YoutubeLink
import components.snackbar.Snackbar
import dev.petuska.kmdc.circular.progress.MDCCircularProgress
import forms.ConditionListEdit
import forms.ConditionUI
import forms.ConditionsDisplay
import forms.ListingAssumableContractListDisplay
import forms.ListingAssumableContractListEdit
import forms.ListingBindingAgreementTermsDisplay
import forms.ListingBindingAgreementTermsEdit
import forms.ListingChattelIncludedListEdit
import forms.ListingChattelsIncludedListDisplay
import forms.ListingDetailsDisplay
import forms.ListingDetailsEdit
import forms.ListingExternalLinksDisplay
import forms.ListingExternalLinksEdit
import forms.ListingFixtureExcludedListEdit
import forms.ListingFixturesExcludedListDisplay
import forms.ListingOwnersDisplay
import forms.ListingOwnersEdit
import forms.ListingPropertyDetailsDisplay
import forms.ListingPropertyDetailsEdit
import forms.WithConditionVM
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import layout.FormContainer
import layout.Header
import layout.InformationBar
import model.returnMetadataFromBackRoute
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.rememberInstance
import style.DiyStyleSheet
import style.GridStyleSheet
import vm.listing.ListingEditScreenViewModel
import vm.listing.ListingEditScreenViewModelConfiguration
import vm.login.UserViewModel

@Composable
fun ListingDetailsPage(userVm: UserViewModel, listingId: Uid<Listing>, user: SessionUser, backRoute: String?) {
  val router = Router.current
  val scope = rememberCoroutineScope()
  val vmConfig by rememberInstance<ListingEditScreenViewModelConfiguration>()
  val vm = remember(scope) {
    ListingEditScreenViewModel(
      scope,
      vmConfig,
      ListingEditScreenEventHandler(
        onPublishSuccess = {
          router.navigate("/home/offers")
        }
      )
    )
  }

  val state by vm.observeStates().collectAsState()

  val saveListing: (Listing?) -> Unit = {
    it?.let { listing ->
      if (state.listing != listing) vm.trySend(Inputs.SaveListing(listing))
    } ?: vm.trySend(Inputs.SetError("Cannot save empty listing. ${CommonMessages.contactAdministrator}"))
  }

  LaunchedEffect(Unit) {
    vm.send(Inputs.FetchListing(listingId))
  }

  if (state.loadingState == LoadingState.UNAUTHORIZED) {
    Unauthorized(userVm, user)
    return
  }

  Snackbar(state.error) { vm.trySend(Inputs.SetError(null)) }

  val submitState = if (state.canSubmit) SubmitState.ENABLED else SubmitState.DISABLED

  val returnMetadata = returnMetadataFromBackRoute(backRoute)

  ListingHeader(
    userVm,
    user,
    if (state.loadingState.isLoading()) "Loading" else null,
    submitState,
    state.listing,
    returnMetadata,
    vm,
  )

  if (state.loadingState == LoadingState.NOT_LOADED) return

  state.listing?.let { ListingDetailsPageView(vm, state, submitState, it, returnMetadata, saveListing) }
}

@Composable
@Suppress("LongParameterList")
private fun ListingHeader(
  userVm: UserViewModel,
  user: SessionUser,
  progressText: String?,
  submitState: SubmitState,
  listing: Listing?,
  returnMetadata: NavButtonMetadata?,
  vm: ListingEditScreenViewModel,
) {
  Header {
    Div({
      classes(GridStyleSheet.flex, GridStyleSheet.alignItemsCenter)
      style { gap(12.px) }
    }) {
      if (progressText != null) {
        MDCCircularProgress()
        Text(progressText)
      } else {
        ChangeLoginStateButton(userVm, user)
        ListingActionButtons(vm, listing, submitState, returnMetadata)
      }
    }
  }
}

@Composable
fun ListingActionButtons(
  vm: ListingEditScreenViewModel,
  listing: Listing?,
  submitState: SubmitState,
  returnMetadata: NavButtonMetadata?,
) {
  ReturnButton(returnMetadata)

  if (listing != null && listing is Listing.Draft) {
    ActionButton(
      disabled = submitState == SubmitState.DISABLED,
      attrs = {
        style { padding(8.px, 12.px) }
        onClick {
          vm.trySend(Inputs.PublishListing(listing))
        }
      }
    ) {
      Text("Submit")
    }
  }
}

@Suppress("LongParameterList")
@Composable
fun ListingDetailsPageView(
  vm: ListingEditScreenViewModel,
  state: State,
  submitState: SubmitState,
  listing: Listing,
  returnMetadata: NavButtonMetadata?,
  saveListing: (Listing?) -> Unit,
) {
  val clock by rememberInstance<Clock>()

  val timeZone = state.sessionUser.timeZone()

  InformationBar {
    if (state.listing?.state in listOf(Listing.State.COMPLETED, Listing.State.LOCKED)) {
      Text("This listing cannot be edited because it's marked as ${state.listing?.state}.")
    } else if ((state.offerCount ?: 0) == 0) {
      when (LocalBrand.current) {
        DIYoffer -> {
          Span {
            Text("Tell us about your property. Please be as detailed as possible. Watch our ")
            YoutubeLink(YoutubeVideo.SELLER_LISTING, "YouTube video")
            Text(".")
          }
        }
        ReAgent -> {
          Span {
            Text("Fill in the property details.")
          }
        }
      }
    } else {
      Text("You cannot edit this listing because there are active offers on it.")
    }
  }

  FormContainer {
    Div({
      classes(DiyStyleSheet.mainForm)
    }) {
      H2 {
        Text("Terms of Property Sale")
      }

      ListingDetailsPropertyOwners(listing, saveListing)
      ListingDetailsPriceAndClosing(listing, saveListing, clock, timeZone)
      ListingDetailsPropertyDetails(state, saveListing, clock, timeZone)
      ListingDetailsAssumableContracts(listing, timeZone, saveListing)
      ListingDetailsFixturesIncluded(listing, saveListing)
      ListingDetailsChattelsIncluded(listing, saveListing)
      ListingDetailsSellerConditions(listing, saveListing)
      ListingDetailsBindingAgreementTerms(listing, saveListing)
      ListingExternalLinks(listing, saveListing)
    }

    FlexRow({ style { justifyContent(JustifyContent.Center) } }) {
      ListingActionButtons(vm, listing, submitState, returnMetadata)
    }
  }
}

@Composable
private fun ListingDetailsPropertyOwners(
  listing: Listing,
  setListing: (Listing) -> Unit,
) {
  val hasValidContact = listing.propertyOwners?.contacts?.let {
    it.none { contact -> contact.name.isBlank() } && it.isNotEmpty()
  } == true
  val hasCertifiedLegalAuthority = listing.propertyOwners?.userCertifiedLegalAuthority?.bool() == true
  val isNew = !hasValidContact || !hasCertifiedLegalAuthority

  val propertyOwnersSectionText = LocalBrand.current.propertyOwnersSectionText

  DetailContainer(
    itemBuilder = ListingPropertyOwnersBuilder(),
    opts = {
      value = listing.propertyOwners
      sectionNumber = 1
      title = propertyOwnersSectionText
      popupRef = "listing-property-owner"
      expanded = isNew
      status = if (isNew) DetailStatus.NEW else DetailStatus.COMPLETED
      onSave = { setListing(listing.baseCopy(propertyOwners = it)) }
    },
    displayContent = { listing.propertyOwners?.let { ListingOwnersDisplay(it) } },
    editContent = { b, sb -> ListingOwnersEdit(b as ListingPropertyOwnersBuilder, sb) }
  )
}

@Composable
private fun ListingDetailsPriceAndClosing(
  listing: Listing,
  setListing: (Listing) -> Unit,
  clock: Clock,
  timeZone: TimeZone,
) {
  DetailContainer(
    itemBuilder = ListingPriceAndClosingBuilder(listing.currency, clock, timeZone),
    opts = {
      value = listing.details
      sectionNumber = 2
      title = ListingSection.LISTING_DETAILS.displayName()
      expanded = listing.details == null
      onSave = { setListing(listing.baseCopy(details = it)) }
    },
    displayContent = { listing.details?.let { ListingDetailsDisplay(it, listing.currency) } },
    editContent = { b, sb -> ListingDetailsEdit(b as ListingPriceAndClosingBuilder, sb) }
  )
}

@Composable
private fun ListingDetailsPropertyDetails(
  state: State,
  setListing: (Listing) -> Unit,
  clock: Clock,
  timeZone: TimeZone,
) {
  state.listing?.let { listing ->
    DetailContainer(
      itemBuilder = ListingPropertyDetailsBuilder(state.jurisdiction(), clock, timeZone),
      opts = {
        value = listing.propertyDetails
        sectionNumber = 3
        title = "Property Details"
        expanded = listing.propertyDetails == null
        onSave = { setListing(listing.baseCopy(propertyDetails = it)) }
      },
      displayContent = {
        listing.propertyDetails?.let {
          ListingPropertyDetailsDisplay(it, listing.currency, Party.SELLER)
        }
      },
      editContent = { b, sb -> ListingPropertyDetailsEdit(false, b as ListingPropertyDetailsBuilder, sb) }
    )
  }
}

@Composable
private fun ListingDetailsAssumableContracts(
  listing: Listing,
  timeZone: TimeZone,
  setListing: (Listing) -> Unit,
) {
  val assumableContractsText = LocalBrand.current.assumableContractsText

  DetailContainer(
    itemBuilder = AssumableContractListBuilder(listing.currency, assumableContractsText),
    opts = {
      value = listing.assumableContracts
      title = assumableContractsText
      sectionNumber = 4
      popupRef = "listing-assumable-contracts"
      expanded = listing.assumableContracts == null
      onSave = { setListing(listing.baseCopy(assumableContracts = it)) }
    },
    displayContent = {
      listing.assumableContracts?.let {
        ListingAssumableContractListDisplay(it, listing.currency)
      }
    },
    editContent = { b, sb ->
      ListingAssumableContractListEdit(
        b as AssumableContractListBuilder,
        listing.currency,
        timeZone,
        sb,
      )
    }
  )
}

@Composable
private fun ListingDetailsFixturesIncluded(
  listing: Listing,
  setListing: (Listing) -> Unit,
) {
  DetailContainer(
    itemBuilder = FixturesExcludedListBuilder(),
    opts = {
      value = listing.fixturesExcluded
      sectionNumber = 5
      title = ListingSection.FIXTURES_EXCLUDED.displayName()
      popupRef = "listing-fixtures"
      expanded = listing.fixturesExcluded == null
      onSave = { setListing(listing.baseCopy(fixturesExcluded = it)) }
    },
    displayContent = { listing.fixturesExcluded?.let { ListingFixturesExcludedListDisplay(it) } },
    editContent = { b, sb -> ListingFixtureExcludedListEdit(b as FixturesExcludedListBuilder, sb) }
  )
}

@Composable
private fun ListingDetailsChattelsIncluded(
  listing: Listing,
  setListing: (Listing) -> Unit,
) {
  DetailContainer(
    itemBuilder = ChattelsIncludedListBuilder(),
    opts = {
      value = listing.chattelsIncluded
      sectionNumber = 6
      title = ListingSection.CHATTELS_INCLUDED.displayName()
      popupRef = "listing-chattels"
      expanded = listing.chattelsIncluded == null
      onSave = { setListing(listing.baseCopy(chattelsIncluded = it)) }
    },
    displayContent = { listing.chattelsIncluded?.let { ListingChattelsIncludedListDisplay(it) } },
    editContent = { b, sb -> ListingChattelIncludedListEdit(b as ChattelsIncludedListBuilder, sb) }
  )
}

@Composable
private fun ListingDetailsSellerConditions(
  listing: Listing,
  setListing: (Listing) -> Unit,
) {
  val filters = listing.buildConditionFilters()
  WithConditionVM { vm, state ->
    LaunchedEffect(listing, filters) {
      vm.send(
        ConditionListContract.Inputs.LoadInitialConditions(
          Party.SELLER,
          filters,
          true,
          listing.sellerConditions?.conditions?.map { it.core.value.get() } ?: emptyList(),
        )
      )
    }

    DetailContainer(
      itemBuilder = ViewModelBuilder(vm) { state },
      opts = {
        value = listing.sellerConditions?.conditions
        sectionNumber = 7
        title = ListingSection.SELLER_CONDITIONS.displayName()
        popupRef = "listing-conditions"
        this.expanded = listing.sellerConditions == null
        onSave = {
          setListing(
            listing.baseCopy(sellerConditions = SellerConditions(it ?: emptyList()))
          )
        }
      },
      displayContent = {
        listing.sellerConditions?.let { sellerCondition ->
          ConditionsDisplay(
            sellerCondition.conditions.map { it.core.value.get() }.map {
              ConditionUI(
                it,
                title = state.getTitle(it)
              )
            },
            editMode = true
          )
        }
      },
      editContent = { _, _ -> ConditionListEdit(state) { vm.trySend(it) } }
    )
  }
}

@Composable
private fun ListingDetailsBindingAgreementTerms(
  listing: Listing,
  setListing: (Listing) -> Unit,
) {
  DetailContainer(
    itemBuilder = ListingBindingAgreementTermsBuilder(),
    opts = {
      value = listing.bindingAgreementTerms
      sectionNumber = 8
      title = ListingSection.BINDING_AGREEMENT_TERMS.displayName()
      popupRef = "listing-binding-agreement-days"
      this.expanded = listing.bindingAgreementTerms == null
      onSave = { setListing(listing.baseCopy(bindingAgreementTerms = it)) }
    },
    displayContent = {
      listing.bindingAgreementTerms?.let {
        ListingBindingAgreementTermsDisplay(
          it.days.core.value.get()
        )
      }
    },
    editContent = { b, sb -> ListingBindingAgreementTermsEdit(b as ListingBindingAgreementTermsBuilder, sb) }
  )
}

@Composable
private fun ListingExternalLinks(
  listing: Listing,
  setListing: (Listing?) -> Unit,
) {
  val externalLinksText = LocalBrand.current.externalLinksText

  DetailContainer(
    itemBuilder = ListingExternalLinksBuilder(),
    opts = {
      value = listing.externalLinks
      sectionNumber = 9
      title = externalLinksText
      popupRef = "listing-external-links"
      expanded = listing.externalLinks == null
      onSave = { setListing(listing.baseCopy(externalLinks = it)) }
    },
    displayContent = { listing.externalLinks?.let { ListingExternalLinksDisplay(it) } },
    editContent = { b, sb -> ListingExternalLinksEdit(b as ListingExternalLinksBuilder, sb) }
  )
}
