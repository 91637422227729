package com.diyoffer.negotiation.ui.brand

import androidx.compose.runtime.staticCompositionLocalOf
import com.diyoffer.negotiation.environment.isDiyOffer
import com.diyoffer.negotiation.environment.isReagent
import com.diyoffer.negotiation.ui.brand.Brand.DIYoffer
import com.diyoffer.negotiation.ui.brand.Brand.ReAgent
import com.diyoffer.negotiation.ui.icons.IconName
import org.w3c.dom.url.URLSearchParams

sealed class Brand {
  // text
  abstract val assumableContractIndefiniteArticleText: String
  abstract val assumableContractText: String
  abstract val assumableContractsText: String
  abstract val buyerAgeConfirmText: String
  abstract val buyerFullNameTextFirst: String
  abstract val buyerFullNameTextSubsequent: String
  abstract val buyerSectionFirst: String
  abstract val buyerSectionSubsequent: String
  abstract val buyerLegalConfirmText: String
  abstract val externalLinksDescription: String
  abstract val externalLinksText: String
  abstract val nameOfBuyersSectionText: String
  abstract val offerCommissionFeesLabelText: String
  abstract val productName: String
  abstract val productShortName: String
  abstract val propertyOwnersSectionText: String
  abstract val sellerFullNameTextFirst: String
  abstract val sellerFullNameTextSubsequent: String
  abstract val sellerSectionFirst: String
  abstract val sellerSectionSubsequent: String
  abstract val supportEmail: String

  // logos and images
  abstract val logo: IconName

  data object DIYoffer : Brand() {
    override val assumableContractIndefiniteArticleText =
      "an"
    override val assumableContractText =
      "Assumable Contract"
    override val assumableContractsText =
      "Assumable Contracts"
    override val buyerAgeConfirmText =
      "Are the buyer(s) at least 18 years old?"
    override val buyerFullNameTextFirst =
      "Full name"
    override val buyerFullNameTextSubsequent =
      "Full name"
    override val buyerSectionFirst =
      "Person"
    override val buyerSectionSubsequent =
      "Person"
    override val buyerLegalConfirmText =
      "I certify that I have full legal authority to represent the buyer(s)."
    override val externalLinksDescription =
      "If available, provide your listing web links (ie: Kijiji, Facebook, etc.)"
    override val externalLinksText: String =
      "External Listing Link(s)"
    override val nameOfBuyersSectionText =
      "Name of Buyer(s)"
    override val offerCommissionFeesLabelText =
      "Buyer Agent Commission"
    override val productName =
      "DIY Negotiations"
    override val productShortName =
      "DIY"
    override val propertyOwnersSectionText =
      "Property Owners"
    override val sellerFullNameTextFirst =
      "Full name"
    override val sellerFullNameTextSubsequent =
      "Full name"
    override val sellerSectionFirst =
      "Person"
    override val sellerSectionSubsequent =
      "Person"
    override val supportEmail =
      "support@diyoffer.ca"

    override val logo = IconName.LOGO
  }
  data object ReAgent : Brand() {
    override val assumableContractIndefiniteArticleText =
      "a"
    override val assumableContractText =
      "Home Contract"
    override val assumableContractsText =
      "Home Contracts"
    override val buyerAgeConfirmText =
      "Are the buyer(s) at least 18 years old?"
    override val buyerFullNameTextFirst =
      "REALTOR® or Buyer name"
    override val buyerFullNameTextSubsequent =
      "REALTOR® or Buyer name"
    override val buyerSectionFirst =
      "REALTOR® or Buyer"
    override val buyerSectionSubsequent =
      "REALTOR® or Buyer"
    override val buyerLegalConfirmText =
      "I/we certify that I/we have full legal authority to represent the Buyer(s), or I am the Buyer of the property."
    override val externalLinksDescription =
      "Include the MLS® property listing link"
    override val externalLinksText: String =
      "MLS® Listing Link"
    override val nameOfBuyersSectionText =
      "REALTOR® and Buyer(s)"
    override val offerCommissionFeesLabelText =
      "Co-operating Commission Fees"
    override val productName =
      "The REAGENT"
    override val productShortName =
      "The REAGENT"
    override val propertyOwnersSectionText =
      "REALTOR® and Property Owners"
    override val sellerFullNameTextFirst =
      "REALTOR® name"
    override val sellerFullNameTextSubsequent =
      "Property Owner"
    override val sellerSectionFirst =
      "REALTOR®"
    override val sellerSectionSubsequent =
      "Property Owner"
    override val supportEmail =
      "support@thereagent.ca"

    override val logo = IconName.REAGENT_LOGO
  }
}

object BrandManager {
  fun brandByOriginHostname(origin: String): Brand? = when {
    isDiyOffer(origin) -> DIYoffer
    isReagent(origin) -> ReAgent
    else -> null
  }

  fun brandByParameter(parameters: URLSearchParams): Brand? = when (parameters.get("brand")?.lowercase()) {
    "diyoffer" -> DIYoffer
    "reagent" -> ReAgent
    else -> null
  }

  fun defaultBrand() = ReAgent
}

val LocalBrand = staticCompositionLocalOf<Brand> { ReAgent }
