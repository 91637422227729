package com.diyoffer.negotiation.environment

import io.ktor.http.*

private const val NAKED_PROD_HOST_DIYOFFER = "diyoffer.com"
private const val NAKED_TEST_HOST_DIYOFFER = "test.diyoffer.com"
private const val NAKED_PROD_HOST_REAGENT = "thereagent.ca"
private const val NAKED_TEST_HOST_REAGENT = "test.thereagent.ca"

val productionHosts = setOf(
  NAKED_PROD_HOST_DIYOFFER,
  NAKED_PROD_HOST_REAGENT,
  "buy.$NAKED_PROD_HOST_DIYOFFER",
  "sell.$NAKED_PROD_HOST_DIYOFFER",
  "buy.$NAKED_PROD_HOST_REAGENT",
  "sell.$NAKED_PROD_HOST_REAGENT",
)

val testHosts = setOf(
  NAKED_TEST_HOST_DIYOFFER,
  NAKED_TEST_HOST_REAGENT,
  "buy-$NAKED_TEST_HOST_DIYOFFER",
  "sell-$NAKED_TEST_HOST_DIYOFFER",
  "buy-$NAKED_TEST_HOST_REAGENT",
  "sell-$NAKED_TEST_HOST_REAGENT",
)

fun URLBuilder.isHosted() = isHosted(host)

fun URLBuilder.isNakedProd() = isNakedProd(host)

fun URLBuilder.isNakedTest() = isNakedTest(host)

fun Url.isHosted() = isHosted(host)

fun Url.isNakedProd() = isNakedProd(host)

fun Url.isNakedTest() = isNakedTest(host)

fun isHosted(domain: String) =
  isNakedProd(domain) ||
    domain.endsWith(".$NAKED_PROD_HOST_DIYOFFER") ||
    domain.endsWith(".$NAKED_PROD_HOST_REAGENT")

fun isNakedProd(domain: String) = domain == NAKED_PROD_HOST_DIYOFFER || domain == NAKED_PROD_HOST_REAGENT

fun isNakedTest(domain: String) = domain == NAKED_TEST_HOST_DIYOFFER || domain == NAKED_TEST_HOST_REAGENT

fun isDiyOffer(domain: String) = domain == NAKED_PROD_HOST_DIYOFFER || domain.endsWith(".$NAKED_PROD_HOST_DIYOFFER")

fun isReagent(domain: String) = domain == NAKED_PROD_HOST_REAGENT || domain.endsWith(".$NAKED_PROD_HOST_REAGENT")
