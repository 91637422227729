package forms

import androidx.compose.runtime.Composable
import builders.ListingPropertyOwnersBuilder
import com.diyoffer.negotiation.common.removeAt
import com.diyoffer.negotiation.common.replaceAt
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.brand.Brand.*
import com.diyoffer.negotiation.ui.brand.LocalBrand
import com.diyoffer.negotiation.ui.icons.IconName
import common.FlatList
import common.FlexColumn
import common.FlexRow
import common.Icon
import common.RadioButton
import common.Row
import common.TextField
import dev.petuska.kmdcx.icons.MDCIcon
import dev.petuska.kmdcx.icons.MDCIconSpan
import kotlinx.datetime.Clock
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Hr
import org.jetbrains.compose.web.dom.Text
import style.DiyStyleSheet
import style.DiyStyleSheet.greyText
import style.DiyStyleSheet.withGreenLeftBorder

@Suppress("LongMethod")
@Composable
fun ListingOwnersEdit(
  builder: ListingPropertyOwnersBuilder,
  setBuilder: (ListingPropertyOwnersBuilder) -> Unit,
) {
  Row({ classes(greyText) }) {
    Text(
      "Includes individuals listed on Title as well as a Spouse/Common-law Partner not listed on Title."
    )
  }

  builder.contacts?.mapIndexed { idx, contact ->
    if (idx > 0) Hr()
    FlexRow({ style { justifyContent(JustifyContent.SpaceBetween) } }) {
      Div({
        style {
          color(DiyStyleSheet.Colors.darkGrey)
          fontWeight(DiyStyleSheet.Weights.darkNormal)
        }
      }) {
        Icon(IconName.PERSON) { style { paddingRight(5.px) } }
        val label = if (idx == 0) LocalBrand.current.sellerSectionFirst else LocalBrand.current.sellerFullNameTextSubsequent
        Text("$label ${if (idx == 0) "" else idx}")
      }
    }

    FlexRow({
      style {
        justifyContent(JustifyContent.SpaceBetween)
        alignItems(AlignItems.FlexStart)
      }
    }) {
      FlexColumn({ classes(withGreenLeftBorder) }) {
        Row {
          val sellerFullNameText =
            if (idx == 0) LocalBrand.current.sellerFullNameTextFirst else LocalBrand.current.sellerFullNameTextSubsequent
          TextField(opts = {
            label = sellerFullNameText
            value = contact.name
            onModified = {
              setBuilder(
                builder.copy(
                  contacts = builder.contacts!!.replaceAt(
                    idx,
                    builder.contacts!![idx].copy(name = it ?: "")
                  )
                )
              )
            }
          })
        }
        Row({ style { marginBottom(0.px) } }) {
          TextField(opts = {
            label = "Email"
            value = contact.firstEmailOrNull()
            onModified = {
              setBuilder(
                builder.copy(
                  contacts = builder.contacts!!.replaceAt(
                    idx,
                    builder.contacts!![idx].copy(
                      methods = listOf(
                        ContactMethod.Email(
                          email = it ?: "",
                          verified = Auditable.Core(Optional.of(false), Clock.System.now())
                        )
                      )
                    )
                  )
                )
              )
            }
          })
        }
      }
      MDCIconSpan(icon = MDCIcon.Delete) {
        style { cursor("pointer") }
        onClick { setBuilder(builder.copy(contacts = builder.contacts?.removeAt(idx))) }
      }
    }
  }

  AddMoreContactButton {
    val listContact = builder.contacts ?: listOf()
    setBuilder(
      builder.copy(
        contacts = listContact + listOf(
          Contact(
            name = "",
            methods = listOf(
              ContactMethod.Email("", Auditable.Core(Optional.of(false), Clock.System.now())),
            ),
          )
        )
      )
    )
  }

  FlexRow {
    val label = when (LocalBrand.current) {
      DIYoffer -> {
        if ((builder.contacts?.size ?: 0) > 1) {
          "I certify that I am one of the property owner(s) listed above, " +
            "or that I have full legal authority to represent the property owners " +
            "e.g. Power of Attorney, REALTOR®, or direct family member."
        } else {
          "I certify that I am the property owner, or that I have full legal authority to " +
            "represent the property owner(s) e.g. Power of Attorney, REALTOR®, or direct " +
            "family member."
        }
      }
      ReAgent -> "I/we certify that I/we represent the property owner(s)."
    }
    RadioButton({
      this.label = label
      value = builder.userCertifiedLegalAuthority
      onModified = { setBuilder(builder.copy(userCertifiedLegalAuthority = it)) }
    })
  }
}

@Composable
fun ListingOwnersDisplay(item: ListingPropertyOwners) {
  item.contacts.map {
    FlatList(
      it.name,
      it.methods.joinToString(", ") { method ->
        when (method) {
          is ContactMethod.Email -> "Email: ${method.email}"
          is ContactMethod.Sms -> "Sms: ${method.telephone}"
        }
      },
    )
  }
}
