@file:Suppress("MaxLineLength")

package com.diyoffer.negotiation.model

import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.model.serdes.*
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

@Serializable
data class ChattelInclusion(
  val optionKey: OptionKey,
  val brand: String?,
  val year: Int?,
  val serialNumber: String?,
)

/**
 * Condition, reflecting the user input of expiry which is in the form of a Duration (e.g. in 12 hours or 2 days or
 * whatever), and then when published the value is enriched with an actual expiry.
 */
@Serializable
data class Condition(
  val optionKey: OptionKey,
  val expiry: Expiry,
  val parameters: Map<String, String> = emptyMap(),
  // The OptionKey.Dynamic refers to a unique condition whereas this key refers to a orthogonal condition
  // that may have a version history. This will be null for Custom keys.
  val key: String? = null,
  // Flag if condition belongs to the set of default conditions and thus cannot be removed in UI
  val default: Boolean = false,
)

// We often need to hydrate the conditionOptions so this help accesses the dynamic ids
val List<Condition>.dynamicIds get() = map { it.optionKey }
  .filterIsInstance<OptionKey.Dynamic>()
  .map { DynamicUid(it._id) }

val List<Condition>.keys get() = mapNotNull { it.key }

@Serializable
sealed class ConditionContent

@Serializable
@SerialName("m")
data class ConditionAsciidocContent(val content: Asciidoc) : ConditionContent()

@Serializable
@SerialName("p")
data class ConditionParamContent(
  val param: String,
  val paramType: ConditionParamType = ConditionParamType.TEXT,
  // this is in PX, the size="4" with character count seems shaky on browsers
  val inputSize: Int = paramType.paramClass.defaultSizePx,
  val placeholder: String? = null,
  val popupHelp: PopupHelp? = null,
) : ConditionContent() {
  enum class ConditionParamType {
    DATE,
    DATE_5PM,
    DATE_TIME,
    DAYS_BUSINESS_COUNT,
    DAYS_CALENDAR_COUNT,
    TEXT,
    MONEY,
    NUMBER,
  }

  enum class ConditionParamClass {
    DATE,
    DATETIME,
    INT,
    MONEY,
    TEXT,
  }

  companion object {
    val ConditionParamType.paramClass: ConditionParamClass get() = when (this) {
      ConditionParamType.DATE -> ConditionParamClass.DATE
      ConditionParamType.DATE_5PM -> ConditionParamClass.DATE
      ConditionParamType.DATE_TIME -> ConditionParamClass.DATETIME
      ConditionParamType.DAYS_BUSINESS_COUNT -> ConditionParamClass.INT
      ConditionParamType.DAYS_CALENDAR_COUNT -> ConditionParamClass.INT
      ConditionParamType.NUMBER -> ConditionParamClass.INT
      ConditionParamType.TEXT -> ConditionParamClass.TEXT
      ConditionParamType.MONEY -> ConditionParamClass.MONEY
    }

    val ConditionParamClass.defaultSizePx get() = when (this) {
      ConditionParamClass.DATE -> 120
      ConditionParamClass.DATETIME -> 200
      ConditionParamClass.INT -> 80
      ConditionParamClass.MONEY -> 120
      ConditionParamClass.TEXT -> 150
    }
  }
}

@Serializable
data class ConditionTemplate(
  val content: List<ConditionContent>,
) {
  fun toAsciiDoc(params: Map<String, String>) = Asciidoc(
    buildString {
      content.forEach { content ->
        when (content) {
          is ConditionAsciidocContent -> append(content.content.text)
          is ConditionParamContent -> params[content.param]?.let { append("*$it*") /* bold param */ }
        }
      }
    }
  )
}

/**
 * Represents a condition defined dynamically in the database. We require the Party the condition is applicable to,
 * the title of the condition (used in drop-downs, for example), a template for the text and parameters of the
 * condition. We also provide some meta-data such as guide anchors, popup help, whether the condition is included
 * by default, and whether the condition is selectable in new listings/offers.
 */
@Serializable
sealed class ConditionOption {
  abstract val core: Core

  @Serializable
  data class Core(
    @Serializable(with = UidSerializer::class) val _id: Uid<OptionKey.Dynamic>,
    // Represents a concept, ie: condition-seller-deposit, that remains the same
    // across versions. If a user has condition-seller-deposit V1 but V2 is available,
    // his listing will still be deemed honoring the condition-seller-deposit default requirement.
    // It's initialized to _id for backward compatibility
    val key: String = _id.toString(),
    val party: Party,
    val title: String,
    val template: ConditionTemplate,
    val guideAnchor: GuideAnchor,
    val popupHelp: PopupHelp,
  ) : ConditionOption() {
    @Transient
    override val core: Core = this
  }

  @Serializable
  data class Enriched(
    override val core: Core,
    /** If the condition is included by default. */
    val default: ConditionDefaultCriteria = ConditionDefaultCriteria.NEVER,
    /** If the template is selectable by users. Older templates that have been replaced may no longer be selectable, but may still be used in older offers. */
    val selectable: Boolean = true,
    /** If available, this clause applies to a specific jurisdiction. If not, it's a "generic" one. **/
    val jurisdiction: Jurisdiction? = null,
    /** Support revision of the same clause. If there is an update, the previous revision become selectable = false **/
    val version: Int = 0,
  ) : ConditionOption() {
    fun keyJurisdiction() = core.key + (jurisdiction?.let { "-${it.key}" } ?: "")
  }
}

@Serializable
data class Jurisdiction(
  /** If we define a jurisdiction, the minim requirements is to narrow it down to a country */
  val country: Country,
  /** However, we can further refine it to the provinceState level for state-specific clauses */
  val provinceState: ProvinceState? = null,
) {
  companion object {
    fun default() = Jurisdiction(Country.default())
  }
  constructor(provinceState: ProvinceState) : this(provinceState.country, provinceState)
  override fun toString() = "${country}${provinceState?.provinceState?.let { " ($it)" } ?: ""}"

  val key get() = country.name.lowercase() + (provinceState?.name?.lowercase()?.let { "-$it" } ?: "")

  fun currency() = country.currency

  fun locale() = when (country) {
    Country.CA -> when (provinceState) {
      // for now use english here too
      ProvinceState.QC -> "en-CA"
      else -> "en-CA"
    }
    Country.US -> "en-US"
  }

  /**
   * Default commission table per province-state.
   */
  @Suppress("MagicNumber")
  fun defaultCommission() = Percent(
    when (provinceState) {
      ProvinceState.ON -> 2.5
      else -> 3.0
    }
  )
}

@Serializable
enum class ConditionDefaultCriteria {
  ALWAYS,
  ASSOCIATION_FEES_NONZERO,
  CONDO,
  NEVER,
  ;

  companion object {
    /** All criteria, except [ConditionDefaultCriteria.NEVER]. */
    fun exceptNever() = listOf(ALWAYS, ASSOCIATION_FEES_NONZERO, CONDO)
  }
}

data class ConditionFilters(
  val criteria: List<ConditionDefaultCriteria> = emptyList(),
  val jurisdiction: Jurisdiction? = null,
)

data class ConditionMarkup(
  val title: String,
  val text: Asciidoc,
)

/**
 * Given an option key, we may have conditions with jurisdiction equal to null (generic), jurisdiction wih country
 * only (country-specific), and jurisdiction with country and province/state (most specific). We want to return
 * the condition with the matching jurisdiction with the highest specificity that we have available.
 */
fun List<ConditionOption.Enriched>.latestVersionFor(jurisdiction: Jurisdiction?): List<ConditionOption.Core> {
  fun List<ConditionOption.Enriched>.maxByVersion() = maxByOrNull { it.version }

  fun List<ConditionOption.Enriched>.latestByCountryAndProvinceState(jurisdiction: Jurisdiction?): ConditionOption.Enriched? {
    if (jurisdiction?.provinceState == null) return null
    return filter { it.jurisdiction?.provinceState == jurisdiction.provinceState }.maxByVersion()
  }

  fun List<ConditionOption.Enriched>.latestByCountry(jurisdiction: Jurisdiction?): ConditionOption.Enriched? {
    if (jurisdiction == null) return null
    return filter {
      it.jurisdiction != null && it.jurisdiction.provinceState == null && it.jurisdiction.country == jurisdiction.country
    }.maxByVersion()
  }

  fun List<ConditionOption.Enriched>.latestGeneric(): ConditionOption.Enriched? =
    filter { it.jurisdiction == null }.maxByVersion()

  return groupBy { it.core.key }
    .mapNotNull { (_, options) ->
      options.latestByCountryAndProvinceState(jurisdiction)
        ?: options.latestByCountry(jurisdiction)
        ?: options.latestGeneric()
    }
    .map { it.core }
    .sortedBy { it.title }
}
