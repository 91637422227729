package com.diyoffer.negotiation.ui.offer

import co.touchlab.kermit.Logger
import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.copperleaf.ballast.observeFlows
import com.copperleaf.ballast.postInput
import com.copperleaf.ballast.repository.cache.getCachedOrNull
import com.diyoffer.negotiation.analytics.AnalyticsClient
import com.diyoffer.negotiation.analytics.AnalyticsEvent
import com.diyoffer.negotiation.common.EMAIL_VALIDATION_REGEX
import com.diyoffer.negotiation.common.removeAt
import com.diyoffer.negotiation.common.replaceAt
import com.diyoffer.negotiation.messages.CommonMessages
import com.diyoffer.negotiation.messages.InfoPopup
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.repository.offer.BuyerOfferRepository
import com.diyoffer.negotiation.repository.offer.SellerOfferRepository
import com.diyoffer.negotiation.repository.user.UserRepository
import com.diyoffer.negotiation.rpcs.IContactVerificationRpcService
import com.diyoffer.negotiation.rpcs.ILinksRpcService
import com.diyoffer.negotiation.services.tryRpc
import com.diyoffer.negotiation.ui.offer.OfferContactsContract.Events
import com.diyoffer.negotiation.ui.offer.OfferContactsContract.Inputs
import com.diyoffer.negotiation.ui.offer.OfferContactsContract.OfferContactUI
import com.diyoffer.negotiation.ui.offer.OfferContactsContract.State
import com.diyoffer.negotiation.ui.offer.OfferContactsContract.VerifyingState
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.datetime.Clock

@Suppress("LongParameterList")
class OfferContactsInputHandler(
  private val buyerOfferRepo: BuyerOfferRepository,
  private val sellerOfferRepo: SellerOfferRepository,
  private val contactVerificationRpcService: IContactVerificationRpcService,
  private val clock: Clock,
  private val linkRpc: ILinksRpcService,
  private val userRepo: UserRepository,
  private val analyticsClient: AnalyticsClient,
) : InputHandler<Inputs, Events, State> {
  @Suppress("LongMethod", "ComplexMethod", "NestedBlockDepth")
  override suspend fun InputHandlerScope<Inputs, Events, State>.handleInput(
    input: Inputs,
  ) = when (input) {
    is Inputs.FetchContacts -> {
      val s = getAndUpdateState { it.copy(offerId = input.offerId) }
      if (s.offerId != input.offerId) {
        observeFlows(
          "OfferContactsInputHandler_ObserveContacts",
          when (input.party) {
            Party.BUYER -> buyerOfferRepo.offer()
              .mapNotNull { it.getCachedOrNull() }.map { Inputs.OfferUpdated(it) }
            Party.SELLER -> sellerOfferRepo.offer(input.offerId)
              .mapNotNull { it.getCachedOrNull() }.map { Inputs.OfferUpdated(it) }
          }
        )
      } else {
        noOp()
      }
    }

    is Inputs.OfferUpdated -> {
      if (input.offerPair.first._id == getCurrentState().offerId) {
        postInput(Inputs.ContactsUpdated(input.offerPair.second))
      } else {
        noOp()
      }
    }

    is Inputs.ContactsUpdated -> {
      updateState { state ->
        state.copy(
          contactsUI = input.contacts?.buyers?.contacts?.mapIndexed { idx, contact ->
            // Backend refreshes offerContacts, but we need to persist some of the local state like OOB input
            val prevContactUI = state.contactsUI.firstOrNull { it?.email in contact.emails() }
            // We only support email for now
            contact.methods.firstOrNull { it is ContactMethod.Email }?.let {
              OfferContactUI(
                name = contact.name,
                email = (it as ContactMethod.Email).email,
                verifiedState = when {
                  it.verified.bool() -> VerifyingState.VERIFIED
                  prevContactUI?.verifiedState == VerifyingState.VERIFYING -> VerifyingState.VERIFYING
                  else -> VerifyingState.UNVERIFIED
                },
                code = prevContactUI?.code ?: "",
                offerContactIdx = idx
              )
            }
          } ?: emptyList(),
          offerContacts = input.contacts
        )
      }
    }

    is Inputs.AddContact -> updateState { it.copy(contactsUI = it.contactsUI.plus(OfferContactUI())) }
    is Inputs.RemoveContact -> {
      updateState { it.copy(contactsUI = it.contactsUI.removeAt(input.contextIdx)) }
    }

    is Inputs.UpdateEmail -> {
      updateContact(input.contactIdx) { contact ->
        require(contact.verifiedState != VerifyingState.VERIFIED)
        contact.copy(email = input.email?.trim())
      }
      doValidation()
    }

    is Inputs.UpdateName -> {
      updateContact(input.contactIdx) { contact ->
        contact.copy(name = input.name)
      }
      doValidation()
    }

    is Inputs.UpdateCodeChar -> {
      updateContact(input.contactIdx) { contact ->
        contact.copy(
          code = input.code?.trim(),
          error = null // Clean error that may have occurred because of wrong digits
        )
      }
    }

    is Inputs.SaveContacts -> {
      try {
        postInput(Inputs.ContactsUpdated(saveContacts(getCurrentState(), true)))
      } catch (e: SaveContactException) {
        postInput(Inputs.SetError(e.message ?: e.toString()))
      }
    }

    is Inputs.VerifyClicked -> {
      try {
        val s = getCurrentState()
        val offerContacts = saveContacts(s, false)
        if (s.offerContacts == null || offerContacts.buyers.contacts.size != s.contactsUI.size) {
          postInput(Inputs.SetError("Could not verify contacts. ${CommonMessages.contactAdministrator}"))
        } else if (s.offerId == null) {
          postInput(
            Inputs.SetError("Could not find the related offer. ${CommonMessages.contactAdministrator}")
          )
        } else if (input.contactIdx !in 0 until s.contactsUI.size) {
          postInput(Inputs.SetError("Selected contact is invalid. ${CommonMessages.contactAdministrator}"))
        } else {
          postInput(Inputs.ContactsUpdated(offerContacts))
          when (s.contactsUI[input.contactIdx]?.verifiedState) {
            VerifyingState.UNVERIFIED -> postInput(Inputs.StartVerification(input.contactIdx))
            VerifyingState.VERIFYING -> postInput(Inputs.CompleteVerification(input.contactIdx))
            else -> noOp()
          }
        }
      } catch (e: SaveContactException) {
        postInput(Inputs.SetError(e.message ?: e.toString()))
      }
    }
    // Reset the state of the contacts verification and restart the process
    is Inputs.ResendClicked -> {
      updateState {
        it.copy(
          contactsUI = it.contactsUI.replaceAt(
            input.contactIdx,
            it.contactsUI[input.contactIdx]?.copy(
              verifiedState = VerifyingState.UNVERIFIED,
              code = ""
            )
          )
        )
      }
      postInput(Inputs.VerifyClicked(input.contactIdx))
    }

    is Inputs.SetError -> {
      input.contactIdx?.let { idx ->
        updateState {
          it.copy(contactsUI = it.contactsUI.replaceAt(idx, it.contactsUI[idx]?.copy(error = input.error)))
        }
      } ?: updateState { it.copy(error = input.error) }
    }

    is Inputs.ClearErrors -> updateState { state ->
      state.copy(
        error = null,
        contactsUI = state.contactsUI.map {
          it?.copy(error = null)
        }
      )
    }

    is Inputs.StartVerification -> {
      startVerification(input.contactIdx, getCurrentState())
    }

    is Inputs.CompleteVerification -> {
      completeVerification(input.contactIdx, getCurrentState())
    }
  }

  // Take the current OfferContact and merge-in user-entered data
  private suspend fun InputHandlerScope<Inputs, Events, State>.buildContacts(): OfferContacts? {
    val s = getCurrentState()
    return s.offerContacts?.let { offerContacts ->
      offerContacts.copy(
        buyers = offerContacts.buyers.copy(
          contacts = s.contactsUI.mapNotNull { it?.toOfferContact() }
        )
      )
    }
  }

  private suspend fun InputHandlerScope<Inputs, Events, State>.updateContact(
    contactIdx: Int,
    contact: (OfferContactUI) -> OfferContactUI,
  ) {
    val s = getCurrentState()
    s.contactsUI.getOrNull(contactIdx)?.let { currContact ->
      updateState {
        it.copy(contactsUI = s.contactsUI.replaceAt(contactIdx, contact(currContact)))
      }
    } ?: noOp()
  }

  private fun OfferContactUI.toOfferContact() = Contact(
    name = name?.trim() ?: "",
    methods = listOf(
      ContactMethod.Email(
        email = email ?: "",
        verified = Auditable.Core(Optional.of(verifiedState == VerifyingState.VERIFIED), clock.now())
      )
    )
  )

  @Suppress("ThrowsCount")
  private suspend fun InputHandlerScope<Inputs, Events, State>.saveContacts(
    s: State,
    fetchOffer: Boolean,
  ): OfferContacts {
    val toSave = buildContacts()
    return if (s.offerId == null) {
      throw SaveContactException("Could not access current offer.")
    } else if (toSave == null) {
      throw SaveContactException("Could not find valid contact to save.")
    } else {
      when (val r = buyerOfferRepo.saveContacts(s.offerId, toSave, fetchOffer)) {
        is OfferContactsSignatureSaveResult.ValidSignature -> when (val saveResult = r.saveResult) {
          is OfferContactsSaveResult.Success -> saveResult.offerContacts
          else -> throw SaveContactException(saveResult.message)
        }

        else -> throw SaveContactException(r.message())
      }
    }
  }

  private suspend fun InputHandlerScope<Inputs, Events, State>.startVerification(
    contactIdx: Int,
    s: State,
  ) {
    require(s.offerContacts != null)
    val contactUI = s.contactsUI[contactIdx]!!
    when (
      contactVerificationRpcService.startVerification(
        UidValue(s.offerId!!),
        s.offerContacts.buyers.contacts[contactUI.offerContactIdx!!]
      )
    ) {
      is StartContactVerificationResult.Success -> {
        Logger.d("StartContactVerificationResult.Success")
        updateState {
          it.copy(
            contactsUI = s.contactsUI.replaceAt(
              contactIdx,
              contactUI.copy(verifiedState = VerifyingState.VERIFYING)
            )
          )
        }
      }

      is StartContactVerificationResult.NotFound -> {
        Logger.d("StartContactVerificationResult.NotFound")
        postInput(
          Inputs.SetError(
            "There has been an error sending the verification email. ${CommonMessages.contactAdministrator}"
          )
        )
      }
    }
  }

  private suspend fun InputHandlerScope<Inputs, Events, State>.completeVerification(contactIdx: Int, s: State) {
    val contactUI = s.contactsUI[contactIdx]!!
    if (contactUI.code == null || contactUI.code.any { !it.isDigit() }) {
      postInput(Inputs.SetError("Verification code should be numbers only", contactIdx))
    } else {
      tryRpc(onException = { _, e ->
        postInput(
          Inputs.SetError(
            "There was an error during the code validation. ${CommonMessages.contactAdministrator(e)}"
          )
        )
      }) {
        when (
          contactVerificationRpcService.completeVerificationViaOobCode(
            UidValue(s.offerId!!),
            s.offerContacts!!.buyers.contacts[contactIdx],
            s.contactsUI[contactIdx]!!.code ?: ""
          )
        ) {
          is VerificationOobCodeResult.Success -> {
            analyticsClient.logEvent(AnalyticsEvent.EmailVerified(s.offerId))
            when (val linkRes = linkRpc.sendBuyerOfferLink(UidValue(s.offerId))) {
              is SendBuyerOfferLinkResult.Success -> {
                // Only show popup if this was not shown before (so null)
                if (!s.validationPopupShown) {
                  userRepo.queuePopup(InfoPopup.BUYER_VERIFIED_CONTACT)
                  updateState { it.copy(validationPopupShown = true) }
                }
              }

              else -> {
                Inputs.SetError("${linkRes.message()} ${CommonMessages.contactAdministrator}")
              }
            }

            buyerOfferRepo.invalidateCache()
            updateState {
              it.copy(
                contactsUI = s.contactsUI.replaceAt(
                  contactIdx,
                  s.contactsUI[contactIdx]!!.copy(
                    verifiedState = VerifyingState.VERIFIED,
                    code = null
                  )
                )
              )
            }
          }
          else -> postInput(Inputs.SetError("Could not validate this code. Please try again.", contactIdx))
        }
      }
    }
  }

  private suspend fun InputHandlerScope<Inputs, Events, State>.doValidation() {
    val s = getCurrentState()
    if (s.contactsUI.isEmpty()) {
      postInput(Inputs.SetError("You must provide the contact information of at least one buyer."))
    } else {
      s.contactsUI.forEachIndexed { idx, contactUI ->
        // For now, one error at a time
        postInput(
          Inputs.SetError(
            if (contactUI?.name == null || contactUI.name.isBlank()) {
              "Contact should have a valid Full Name."
            } else if (contactUI.email == null || !EMAIL_VALIDATION_REGEX.matches(contactUI.email)) {
              "Contact should have a valid email."
            } else {
              null
            },
            idx
          )
        )
      }
    }
  }
}

class SaveContactException(message: String) : RuntimeException(message)
