package common

import androidx.compose.runtime.Composable
import com.diyoffer.negotiation.ui.icons.IconName
import com.diyoffer.negotiation.ui.offer.TagColor
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLSpanElement
import style.DiyStyleSheet
import style.GridStyleSheet.alignItemsCenter
import style.GridStyleSheet.flex

@Composable
fun Tag(
  name: String,
  iconName: IconName? = null,
  color: TagColor = TagColor.YELLOW,
  attrs: AttrBuilderContext<HTMLSpanElement>? = null,
) {
  val (textColor, backgroundColor) = getColor(color)
  Span({
    classes(flex, alignItemsCenter)
    style {
      padding(4.px, 8.px)
      backgroundColor(backgroundColor)
      color(textColor)
      borderRadius(4.px)
    }
    attrs?.invoke(this)
  }) {
    if (iconName != null) {
      Icon(iconName) {
        style { marginRight(6.px) }
      }
    }
    Span({
      style {
        fontWeight(DiyStyleSheet.Weights.darkNormal)
      }
    }) {
      Text(name)
    }
  }
}

@Composable
fun TagText(
  text: String,
  color: TagColor = TagColor.YELLOW,
  attrs: AttrBuilderContext<HTMLSpanElement>? = null,
) {
  val (textColor, backgroundColor) = getColor(color)
  Span({
    classes(flex, alignItemsCenter)
    style {
      padding(4.px, 8.px)
      backgroundColor(backgroundColor)
      color(textColor)
      borderRadius(4.px)
    }
  }) {
    Span({
      attrs?.invoke(this)
    }) {
      Text(text)
    }
  }
}

private fun getColor(color: TagColor): Pair<CSSColorValue, CSSColorValue> {
  val textColor: CSSColorValue
  val backgroundColor: CSSColorValue
  when (color) {
    TagColor.LIGHT_GREEN -> {
      textColor = DiyStyleSheet.Colors.green
      backgroundColor = DiyStyleSheet.Colors.lightGreen
    }
    TagColor.GREEN -> {
      textColor = DiyStyleSheet.Colors.white
      backgroundColor = DiyStyleSheet.Colors.green
    }
    TagColor.RED -> {
      textColor = DiyStyleSheet.Colors.red
      backgroundColor = DiyStyleSheet.Colors.lightRed
    }
    TagColor.BLUE -> {
      textColor = DiyStyleSheet.Colors.lightBlue
      backgroundColor = DiyStyleSheet.Colors.blue
    }
    TagColor.DARK_BLUE -> {
      textColor = DiyStyleSheet.Colors.white
      backgroundColor = DiyStyleSheet.Colors.darkBlue
    }
    TagColor.GREY -> {
      textColor = DiyStyleSheet.Colors.grey
      backgroundColor = DiyStyleSheet.Colors.lightGrey
    }
    TagColor.DARK_GREY -> {
      textColor = DiyStyleSheet.Colors.white
      backgroundColor = Color("#969696")
    }
    else -> {
      textColor = DiyStyleSheet.Colors.yellow
      backgroundColor = DiyStyleSheet.Colors.lightYellow
    }
  }
  return textColor to backgroundColor
}
