package forms.auth

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import com.diyoffer.negotiation.ui.login.ResetPasswordScreenContract.Inputs
import com.diyoffer.negotiation.ui.login.ResetPasswordScreenEventHandler
import common.ActionButton
import common.GoBackButton
import common.TextField
import common.ValidationMessage
import layout.Logo
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.rememberInstance
import style.DiyStyleSheet
import style.GridStyleSheet.flex
import style.GridStyleSheet.flexColumn
import style.SignFormStyleSheet
import vm.login.ResetPasswordViewModel
import vm.login.ResetPasswordViewModelConfiguration

/**
 * The signin window will show Email, Password by default, with Forgot password.
 * To create an account with email, user needs to click on "Do not have an account? Sign Up".
 * The Google and Facebook social login are after the -- or -- divider.
 */
@Suppress("LongMethod")
@Composable
fun ResetPassword() {
  val scope = rememberCoroutineScope()
  val vmConfig by rememberInstance<ResetPasswordViewModelConfiguration>()
  val vm = remember(scope) {
    ResetPasswordViewModel(
      config = vmConfig,
      eventHandler = ResetPasswordScreenEventHandler(),
      viewModelCoroutineScope = scope
    )
  }

  val state by vm.observeStates().collectAsState()

  Div({
    classes(flex, flexColumn)
    style { width(366.px) }
  }) {
    Logo(true)
    Span({
      style {
        color(DiyStyleSheet.Colors.darkBlue)
        fontWeight(DiyStyleSheet.Weights.darkNormal)
        fontSize(18.px)
        marginBottom(8.px)
      }
    }) {
      Text("Reset Password")
    }

    Span({
      style {
        color(DiyStyleSheet.Colors.darkGrey)
      }
    }) {
      if (state.success != true) {
        Text("Enter the email you used to login and we'll send you instructions to reset your password.")
      } else {
        Div {
          P { Text("You will shortly receive instructions via email to reset your password.") }
          GoBackButton(label = "Back to sign-in", path = "/auth/sign-in")
        }
      }
    }

    if (state.success != true) {
      Div({
        style { margin(20.px, 0.px, 32.px, 0.px) }
      }) {
        TextField(
          opts = {
            label = "Email"
            value = state.email
            onModified = { vm.trySend(Inputs.EmailChanged(it)) }
          },
          attrs = {
            style { height(44.px) }
          }
        )
      }

      ValidationMessage(state.emailError)
      ValidationMessage(state.error)

      passwordActionButton("Reset Password") { vm.trySend(Inputs.ResetWithEmail) }
      GoBackButton(label = "Back to sign-in", path = "/auth/sign-in")
    }
  }
}

@Composable
fun passwordActionButton(label: String, onClick: () -> Unit) {
  ActionButton(attrs = {
    classes(SignFormStyleSheet.authActionButton)
    style { marginBottom(12.px) }
    onClick { onClick() }
  }) {
    Text(label)
  }
}
