package forms.auth

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import app.softwork.routingcompose.Router
import app.softwork.routingcompose.navigate
import com.diyoffer.negotiation.auth.FirebaseAuth
import com.diyoffer.negotiation.model.auth.*
import com.diyoffer.negotiation.ui.login.SignInScreenContract.Inputs
import com.diyoffer.negotiation.ui.login.SignInScreenEventHandler
import common.ActionButton
import common.Button
import common.FlexRow
import common.GoBackButton
import common.TextField
import common.TextFieldPassword
import common.ValidationMessage
import components.MessageFromQuery
import components.SigningMode
import components.TermsOfService
import components.WithLoadingOverlay
import dev.petuska.kmdcx.icons.MDCIcon
import dev.petuska.kmdcx.icons.MDCIconSpan
import dev.petuska.kmdcx.icons.MDCIconType
import kotlinx.coroutines.launch
import layout.Logo
import model.LoginAction
import model.nextActionParam
import org.jetbrains.compose.web.attributes.AutoComplete
import org.jetbrains.compose.web.attributes.autoComplete
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.left
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Form
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.rememberInstance
import org.w3c.dom.HTMLButtonElement
import style.DiyStyleSheet
import style.DiyStyleSheet.Sizes.padding
import style.DiyStyleSheet.buttonLink
import style.GridStyleSheet.flex
import style.GridStyleSheet.flexColumn
import style.GridStyleSheet.justifyContentCenter
import style.SignFormStyleSheet
import style.SignFormStyleSheet.SocialButton
import style.SignFormStyleSheet.SocialButtonEmphasis
import vm.login.SignInViewModel
import vm.login.SignInViewModelConfiguration

/**
 * The signin window will show Email, Password by default, with Forgot password.
 * To create an account with email, user needs to click on "Do not have an account? Sign Up".
 * The Google and Facebook social login are after the -- or -- divider.
 */
@Suppress("LongMethod")
@Composable
fun SignIn(nextAction: String? = null) {
  val router = Router.current
  val scope = rememberCoroutineScope()
  val vmConfig by rememberInstance<SignInViewModelConfiguration>()
  val firebaseAuth by rememberInstance<FirebaseAuth>()
  val vm = remember(scope) {
    SignInViewModel(
      config = vmConfig,
      eventHandler = SignInScreenEventHandler(
        onSuccess = { router.navigate(nextAction ?: "/home") }
      ),
      viewModelCoroutineScope = scope,
      firebaseAuth = firebaseAuth,
    )
  }

  val state by vm.observeStates().collectAsState()

  Div({
    classes(flex, flexColumn)
    style { width(366.px) }
  }) {
    Logo(true)

    MessageFromQuery()

    WithLoadingOverlay(state.loadingState.isLoading()) {
      Form {
        TextField(
          opts = {
            label = "Email"
            value = state.email
            onModified = { vm.trySend(Inputs.UsernameChanged(it)) }
          },
          attrs = {
            autoComplete(AutoComplete.email)
            style { height(44.px) }
          }
        )

        ValidationMessage(state.emailError)

        Div({
          style { marginTop(20.px) }
        }) {
          TextFieldPassword(opts = {
            label = "Password"
            value = state.password
            onModified = { vm.trySend(Inputs.PasswordChanged(it)) }
            onKeyEnter = {
              vm.trySend(Inputs.SignInWithEmailAndPassword)
            }
          }) {
            autoComplete(AutoComplete.currentPassword)
            style { height(44.px) }
          }
        }

        ValidationMessage(state.passwordError)
      }

      Span(attrs = {
        classes(buttonLink)
        style { margin(8.px, 0.px, 8.px, 0.px) }
        onClick { router.navigate("/auth/reset-password") }
      }) {
        Text("Forgot Password?")
      }

      ValidationMessage(state.error)

      ActionButton(
        attrs = {
          classes(SignFormStyleSheet.authActionButton)
          style {
            margin(12.px, 0.px)
          }
          onClick {
            vm.trySend(Inputs.SignInWithEmailAndPassword)
          }
        }
      ) {
        Text("Sign in")
      }

      Div({
        classes(flex, justifyContentCenter)
      }) {
        Text("Do not have an account?")
        Span(attrs = {
          classes(buttonLink)
          style { marginLeft(5.px) }
          onClick { router.navigate("/auth/sign-up", nextActionParam(nextAction)) }
        }) {
          Text("Sign up")
        }
      }

      Div({
        classes("separator")
      }) {
        Text("Or")
      }

      Div({
        classes(flex, flexColumn)
        style { gap(16.px) }
      }) {
        SocialButton(
          "Google",
          LoginAction.IN,
          state.linkCredentials?.currentProviderId == ProviderId.GOOGLE
        ) {
          onClick {
            scope.launch {
              vm.obtainSocialAuthCredentialsAndTriggerSignIn(
                ProviderId.GOOGLE,
                false,
                state.linkCredentials?.loginHint,
              )
            }
          }
        }
        SocialButton(
          "Facebook",
          LoginAction.IN,
          state.linkCredentials?.currentProviderId == ProviderId.FACEBOOK
        ) {
          onClick {
            scope.launch {
              vm.obtainSocialAuthCredentialsAndTriggerSignIn(
                ProviderId.FACEBOOK,
                false,
                state.linkCredentials?.loginHint,
              )
            }
          }
        }
      }

      ValidationMessage(state.socialError) {
        style { marginTop(16.px) }
      }
    }
  }

  FlexRow({
    style {
      paddingTop(padding)
      justifyContent(JustifyContent.Center)
    }
  }) {
    Span { TermsOfService(SigningMode.SIGN_IN, true) }
  }

  GoBackButton { style { paddingBottom(0.px) } }
}

@Composable
fun SocialButton(
  type: String,
  action: LoginAction,
  emphasis: Boolean,
  attrs: AttrBuilderContext<HTMLButtonElement>? = null,
) {
  Button({
    classes(DiyStyleSheet.button, if (emphasis) SocialButtonEmphasis else SocialButton)
    attrs?.invoke(this)
  }) {
    Div(attrs = { style { flexGrow(1) } }) {
      Img("images/${type.lowercase()}-logo.svg") {
        style {
          marginRight(10.px)
        }
      }
      Text(
        "Sign ${
        when (action) {
          LoginAction.UP -> "up"
          LoginAction.IN -> "in"
        }
        } with $type"
      )
    }
    if (emphasis) {
      MDCIconSpan(
        type = MDCIconType.Outlined,
        icon = MDCIcon.TouchApp,
        attrs = {
          @Suppress("MagicNumber")
          style {
            color(Color.green)
            fontSize(20.px)
            width(0.px)
            left((-10).px)
            position(Position.Relative)
          }
        }
      )
    }
  }
}
